import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import themes from "./infinityTheme";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import OfferDiscoveryHome from "./modules/home/OfferDiscoveryHome";
import CampaignHome from "./modules/campaignInfo/CampaignHome";
import RedeemCoupon from "./modules/redemptions/RedeemCoupon"
import CommonRedeemScreen from "./modules/redemptions/CommonRedeemScreen"
import LearMore from './modules/cookies/LearnMore';
import PageNotFound from "./modules/notfound/PageNotFound";
import ContactUsForm from "./modules/contactus/ContactUsForm";
import { updateConfig } from "../src/actions/offers";
import {getDirection} from './commons/utils'
import CustomisedComponentView from './commons/CustomisedComponentView'
import { updateLanguage } from '../src/actions/language'
import WinnersList from './modules/watch-n-win/WinnersList'
import CustomerInfoForm from './commons/CustomerInfoForm'
import SurveyFeedback from './commons/SurveyFeedback'
const queryString = require('query-string');

const Routes = (props) => {
    const dispatch = useDispatch();
    const language = useSelector(state => state.offers.language);
    const direction = getDirection(language);
    const parsedQueryString = queryString.parse(window.location.search); // fetch language from query param


    // import config json and add to redux variable
    useEffect(() => {
        fetch("/config/config.json")
            .then(res => res.json())
            .then((json) => {
                dispatch(updateConfig(json));
            })
    }, []);

    // update language based on query param
    useEffect(() => {
        if(parsedQueryString.lang){
        dispatch(updateLanguage(parsedQueryString.lang));
        }
    }, []);

    return (
        <div
            style={{ direction: direction }} >
            <ThemeProvider theme={themes}>
                <CssBaseline />
                <BrowserRouter >
                    <Switch>
                        <Route exact path="/" component={OfferDiscoveryHome} />
                        <Route exact path="/survey/:code" component={SurveyFeedback} />
                        <Route exact path="/learnMore" component={LearMore} />
                        <Route exact path="/coupon/redeem" component={RedeemCoupon} />
                        <Route exact path="/redeem/coupon" component={CommonRedeemScreen} />
                        <Route exact path="/winnersList" component={WinnersList} />
                        <Route exact path="/customerInfoForm" component={CustomerInfoForm} />
                        <Route  path="/customisedView" component={CustomisedComponentView}/>
                        <Route path="/formContacts" component={ContactUsForm} />
                        <Route path="/campaign/:code" component={CampaignHome} />
                        <Route path="/:code" component={CampaignHome} />
                        <Route exact path="/notfound" component={PageNotFound} />
                        <Route component={Error} />
                    </Switch>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    )
}
export default Routes;
