import React from "react";
import { makeStyles} from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { injectIntl, intlShape } from "react-intl";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(10),
    paddingLeft: theme.spacing(10)

  },
  formControl:{
    width:'120px'
  }
}));

const  SelectOption=(props)=> {
  const classes = useStyles();
  const [values, setValues] = useState({sortOption:''});

  function handleChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value

    }));
    props.handleSelect(event.target.value)
  }

 
  return (
    <div style={{"marginLeft":"auto"}}>
      <form autoComplete="off">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="sort-by">{props.intl.formatMessage({id: 'sortBy'})}</InputLabel>
          <Select
            value={values.sortOption}
            onChange={handleChange}
            inputProps={{
              name: 'sortOption',
              id: 'sort-by'
            }}
          >
            {props.selectOptions.map(item => {
              return <MenuItem value={item.id}>{item.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </form>
    </div>
  );
}

export default injectIntl(SelectOption);