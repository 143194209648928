const intialStates = {
  config: { primaryMenuItem: [], menuItems: [], province_ne: [], 
            province_en: [], idTypeList_ne: [], idTypeList_en: [], 
            preApprovedList_ne: [], preApprovedList_en: [],
            vehicleType_ne:[],vehicleType_en:[],
            preReqPeriodList_ne:[],preReqPeriodList_en:[],
            noOfPassengers_ne:[],noOfPassengers_en:[],
            surveyQuestions:{}
          }
};

export const config = (state, action) => {
  if (!state) {
    state = intialStates;
  }
  const newState = { ...state };
  switch (action && action.type) {
    case "UPDATE_CONFIG": {
      newState.config = action.payload;
      return {
        ...newState
      };
    }
    default:
      return {
        ...state
      };
  }

}