import Button from '@material-ui/core/Button';
import { grey } from "@material-ui/core/colors";
import InputLabel from "@material-ui/core/InputLabel";
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from "@material-ui/styles";
import React, { useState } from 'react';
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import AppMenu from "../modules/commons/AppMenu";
import ApiErrorHandler from './apiErrorHandler';

const rs = require('jsrsasign');
const queryString = require('query-string');

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    responseFont: {
        fontSize: "15px",
        fontWeight: "bold"
    },
    surveyErrorFont: {
        fontSize: "17px",
        fontWeight: "bold",
        color: "red"
    },
    optionContainer: {
        display: "flex",
        flexDirection: "column"
    },
    questionContainer: {
        padding: "5px"
    },
    optionButtons: {
        margin: "5px",
        textTransform: "none"
    },
    errorMessage: {
        color: 'red'
    }
}));

var isJwtValid = false;
const getDecodedMobileNumber = (encodedMsisdn) => {
    isJwtValid = rs.KJUR.jws.JWS.verify(encodedMsisdn, "692e70406d2e63", ["HS256"]);
    if (isJwtValid) {
        let decodedMsisdn = rs.KJUR.jws.JWS.parse(encodedMsisdn)["payloadPP"];
        return decodedMsisdn;
    }
    return '';
}
const parsedQueryString = queryString.parse(window.location.search); // fetch language from query param
const mobileNumber = (parsedQueryString.user) ? getDecodedMobileNumber(parsedQueryString.user) : '';
function SurveyFeedback(props) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const language = useSelector(state => state.offers.language);
    const config = useSelector(state => state.config);
    const couponRedeemUrl = config.config.couponRedeemUrl;
    const rewardCode = config.config.surveyRewardCode;
    const countryCode = config.config.countryCode;
    const locale = useSelector(state => state.offers.language);
    const supplierId = config.config.supplierId;
    const surveyCampaignId = config.config.surveyCampaignId;
    const surveyQuestions = config.config.surveyQuestions[language];
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [response, setResponse] = useState('');
    const [state, setState] = useState({
        nameValue: '', questionToShow: 0, askNumber: false, emailAddress: '', surveyDetail: '', termsCondition: false,
        futurePromotion: false, mobileNumber: mobileNumber, apiResponse: ''
    });
    const [error, setError] = useState({ emailAddress: '', nameValue: '' });
    const showApiResponse = (message) => {
        setResponse(props.intl.formatMessage({ id: message }));
        setShowResponseModal(true);
    }
    let surveyAnswers = '';
    if (!surveyQuestions)
        return <span />

    const customButton = createMuiTheme({
        palette: {
            primary: {
                light: grey[800],
                main: grey[800],
                dark: grey[800],
                backgroundColor: grey[800],
            }
        },
        typography: {
            button: {
                textTransform: "none",
                color: "black"
            }
        }
    });

    let handleSurvey = (qId, option) => {
        surveyAnswers = state.surveyDetail + `${qId}:${option}|`;
        setState({ ...state, questionToShow: state.questionToShow + 1, surveyDetail: surveyAnswers });

        if (surveyQuestions.length === state.questionToShow + 1) {
            setState({ ...state, askNumber: true, surveyDetail: surveyAnswers })
        }
    }

    const redeemCouponAPiCall = (surveyInfo = '', surveyQuestionAnswer = '') => {
        const countryCodeNoPlus = countryCode.split('+').join('');
        let phoneNumber = (parsedQueryString.user) ? mobileNumber.split('+').join('') : countryCodeNoPlus + state.mobileNumber;
        let url = couponRedeemUrl;
        const payLoad = {
            code: rewardCode,
            campaignId: props.campaignId ? props.campaignId : surveyCampaignId,
            sender: phoneNumber,
            channelType: "PORTAL",
            supplierId: supplierId,
            responseAsMessage: false,
            transactionId: state.surveyDetail,
            termsCondition: surveyInfo.termsCondition,
            futurePromotion: surveyInfo.futurePromotion,
            preferredLanguage: locale,
            type: "code",
            user: {
                mobile: phoneNumber,
                name: surveyInfo.nameValue,
                email: surveyInfo.emailAddress
            }
        };
        // holds good for only nginx configuration
        fetch(
            url,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payLoad)
            }
        )
            .then(async result => {
                let apiResponse = await ApiErrorHandler(result);
                showApiResponse(apiResponse)
            })
            .catch((e) => {
                let apiResponse = ApiErrorHandler(e);
                showApiResponse(apiResponse)
                console.log(`%cerror in redeeming code>>>>>>>>>>>>>>>>>>> ${e}`, 'color: white; background-color: red; font-size: 20px; padding: 3px;');

            })
    };

    const handleContactInfo = (event) => {
        redeemCouponAPiCall();
        event.preventDefault();
    }

    let askNumber =
        <div >
            <form onSubmit={handleContactInfo} className={classes.form}>
                <div >
                    <Typography align="right" gutterBottom variant="h5" component="h5">
                        {props.intl.formatMessage({ id: "step" }, { digit: state.questionToShow + 2, total: surveyQuestions.length + 1 })}
                    </Typography>
                </div>
                <Typography gutterBottom variant="h4" component="h4">
                    {props.intl.formatMessage({ id: "thankYouParticipation" })}
                </Typography>
                <br />
                <p id="mobileNo-required-label">
                    {props.intl.formatMessage({ id: "mobileNo" })}
                </p>
                <TextField
                    id="mobileNumber"
                    name="mobileNumber"
                    variant="filled"
                    label="Required"
                    value={state.mobileNumber}
                    InputProps={{
                        readOnly: true
                    }}
                    // onChange={(e) => handleInput(e, "mobileNumber")}
                    label={props.intl.formatMessage({ id: "mobileNo" })}
                    required
                    fullWidth
                />
                <br /><br />
                <Button variant="contained" color="primary" type="submit" >
                    {props.intl.formatMessage({ id: "contactus.form.customer.submit" })}
                </Button>
            </form>
        </div>

    let question =
        < div className={classes.questionContainer} >
            <div>
                <Typography align="right" gutterBottom variant="h5" component="h5">
                    {props.intl.formatMessage({ id: "step" }, { digit: state.questionToShow + 1, total: surveyQuestions.length + 1 })}
                </Typography>
            </div>
            <Typography gutterBottom variant="h4" component="h4">
                {props.intl.formatMessage({ id: "surveyTitle" })}
            </Typography>
            <br />
            <InputLabel id="question">
                {surveyQuestions[state.questionToShow].question}
            </InputLabel>
            <br />
            <div className={classes.optionContainer}>
                <ThemeProvider theme={customButton}>
                    {surveyQuestions[state.questionToShow].options.map((option) => {
                        return <Button variant="outlined" color="primary" className={classes.optionButtons}
                            onClick={(e) => handleSurvey(surveyQuestions[state.questionToShow].qId, option)}>
                            {option}</Button>
                    })}
                </ThemeProvider>
            </div>
        </div >

    let questionsPage = (state.askNumber && surveyQuestions.length !== state.questionToShow) ? <div>{askNumber}</div> : <div>{question}</div>
    let pageToShow = (showResponseModal) ? <span className={classes.responseFont}>{response}</span> : questionsPage
    return (
        <div>
            <AppMenu />
            <div style={modalStyle} className={classes.paper}>
                {(isJwtValid) ? pageToShow : <span className={classes.surveyErrorFont}>{props.intl.formatMessage({ id: "surveyError" })}</span> }
            </div>
        </div>
    )
}

export default injectIntl(SurveyFeedback);