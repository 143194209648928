import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import {validate} from '../../commons/validation'
import DialogCommon from "../../commons/DialogCommon";
import { injectIntl, intlShape } from "react-intl";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    marginTop: theme.spacing(3)
  },
  group: {
    margin: theme.spacing(1, 0)
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    //marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  errorMessage: {
    color: 'red'
  },
  checkboxWrapper:{
    marginLeft:"12px"
  }
}));

const FormForEnterprise = ({ intl }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({ emailAddress : '',mobileNumber:'', errStatus:false, nameValue:'',countryErr:'',tellUsMore:''});
  
  const [state, setState] = useState({
    salesBooster: false,
    customerAcquisition: false,
    appUsageBooster: false,
    awarenessBooster: false,
    nameValue: "",
    emailAddress: "",
    mobileNumber: "",
    country: "",
    tellUsMore: ""
  });

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setState({
    salesBooster: false,
    customerAcquisition: false,
    appUsageBooster: false,
    awarenessBooster: false,
    nameValue: "",
    emailAddress: "",
    mobileNumber: "",
    country: "",
    tellUsMore: ""
    })
  }

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });

  };

  const handleInput = (event, input) => {
    setState({ ...state, [input]: event.target.value });
    const res = validate(input, event.target.value);
    const name = res.name;
    if (!res.formIsValid) {
      setError({ ...error, [name]: intl.formatMessage({id: res.errMsg}), ['errStatus']:true });
    }
     else {
      setError({ ...error, [name]: '' ,['errStatus']:false});
    }

  };

  const handleSubmitForEnterpriseFeedback = event => {
    var interestType = [];
    if (state.appUsageBooster === true)
      interestType.push("APP_USAGE_BOOSTER");
    if (state.salesBooster === true)
      interestType.push("SALES_BOOSTER");
    if (state.customerAcquisition === true)
      interestType.push("CUSTOMER_ACQUISITION");
    if (state.awarenessBooster === true)
      interestType.push("AWARENESS_BOOSTER");
    var payLoad = { name: state.nameValue, emailId: state.emailAddress, mobileNumber: state.mobileNumber, country: state.country, query: state.tellUsMore, interest: interestType };
    if (! error.errStatus){
      fetch(
        "/feedback/enterprise/",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payLoad)
        }
      )
        .then(response => {
  
          if (response.status === 200)
            handleClickOpen();
        })
        .catch(exception => {
          console.log(exception);
        });
    }
    event.preventDefault();
  };
const {salesBooster,customerAcquisition,appUsageBooster,awarenessBooster,nameValue,emailAddress,mobileNumber,country,tellUsMore} = state;

  return (
    <div>
      <DialogCommon handleClose={handleClose} propsVal={state} openVal={open} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h3" variant="h3">
            {intl.formatMessage({ id: 'contactus.please.fill' })}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmitForEnterpriseFeedback}>
            <TextField onChange={e => handleInput(e, "nameValue")} variant="outlined"
              margin="normal"  required fullWidth id="name" label={intl.formatMessage({ id: 'contactus.form.enterprise.name' })}
              name="name" value={nameValue}/><span className = {classes.errorMessage}>{error.nameValue}</span>
            <TextField onChange={e => handleInput(e, "emailAddress")} variant="outlined" margin="normal"
              required fullWidth id="email" label={intl.formatMessage({ id: 'contactus.form.enterprise.email' })}
              name="email" autoComplete="email" value={emailAddress} autoFocus/>
              <span className = {classes.errorMessage}>{error.emailAddress}</span>
            <TextField onChange={e => handleInput(e, "mobileNumber")} variant="outlined" margin="normal"
              fullWidth name="mobileNumber" label={intl.formatMessage({ id: 'contactus.form.enterprise.businesscontact' })}
              id="Mobile Number" value={mobileNumber}/>
              <span className = {classes.errorMessage}>{error.mobileNumber}</span>
            <TextField onChange={e => handleInput(e, "country")} variant="outlined" margin="normal"
              required fullWidth name="country" label={intl.formatMessage({ id: 'contactus.form.enterprise.country' })}
              id="Country" value={country}/><span className = {classes.errorMessage}>{error.countryErr}</span>
           
            {/* interested in section starts here */}
            <div>
            <FormControl component="fieldset" className={classes.formControl}>
              
                <Typography component="h3" variant="h3">
                  {intl.formatMessage({ id: 'contactus.form.enterprise.interestin' })}
                </Typography>
             
              <FormGroup className={classes.checkboxWrapper}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={salesBooster}
                      onChange={handleChange("salesBooster")}
                      value="salesBooster"
                    />
                  }
                  label={intl.formatMessage({ id: 'contactus.form.interest.salesBooster' })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customerAcquisition}
                      onChange={handleChange("customerAcquisition")}
                      value="customerAcquisition"
                    />
                  }
                  label={intl.formatMessage({ id: 'contactus.form.interest.ca' })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={appUsageBooster}
                      onChange={handleChange("appUsageBooster")}
                      value="appUsageBooster"
                    />
                  }
                  label={intl.formatMessage({ id: 'contactus.form.interest.aub' })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={awarenessBooster}
                      onChange={handleChange("awarenessBooster")}
                      value="awarenessBooster"
                    />
                  }
                  label={intl.formatMessage({ id: 'contactus.form.interest.ab' })}
                />
              </FormGroup>
            </FormControl>
            </div>
            {/* interested in section ends here */}
            <TextField
              onChange={e => handleInput(e, "tellUsMore")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="tellUsMore"
              label={intl.formatMessage({ id: 'contactus.form.customer.tellusmore' })}
              id="tellUsMore"
              value={tellUsMore}
            /><span className = {classes.errorMessage}>{error.tellUsMore}</span>
            <Button color="primary"
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              {intl.formatMessage({ id: 'contactus.form.customer.submit' })}
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
}

FormForEnterprise.propTypes = {
  intl: intlShape.isRequired
};


export default injectIntl(FormForEnterprise);
