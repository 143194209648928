import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import { injectIntl, intlShape } from "react-intl";
import DialogCommon from "../../commons/DialogCommon";
import {validate} from '../../commons/validation'

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    marginTop: theme.spacing(3)
  },
  group: {
    margin: theme.spacing(1, 0)
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    display: "flex",
    flexDirection: "column"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  errorMessage: {
    color: 'red'
  }

}));



const FormForCustomer = ({ intl }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({ emailAddress : '',mobileNumber:'',errStatus:false, nameValue:'',tellUsMore:'',campaignName:''});
  const [state, setState] = useState({ dropDownValue: "generalQuery", nameValue: "", emailAddress: "", mobileNumber: "", campaignName: "", tellUsMore: "" });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setState({ dropDownValue: "generalQuery", nameValue: "", emailAddress: "", mobileNumber: "", campaignName: "", tellUsMore: "" })
  };
  function handleChangeForRadioButton(event) {
    setState({
      dropDownValue: event.target.value, emailAddress: state.emailAddress, mobileNumber: state.mobileNumber,
      campaignName: state.campaignName, tellUsMore: state.tellUsMore, nameValue: state.nameValue
    });
  }

  function handleInput(event, input) {
    setState({ ...state, [input]: event.target.value });
    const res = validate(input, event.target.value);
    const name = res.name;
    if (!res.formIsValid) {
      setError({ ...error, [name]: intl.formatMessage({id: res.errMsg}), ['errStatus']:true });
    }
     else {
      setError({ ...error, [name]: '',['errStatus']:false });
    }
  }

  const handleSubmitForCustomerFeedBack = event => {
    var purposeVal = "";
    if (state.dropDownValue === "complaint")
      purposeVal = "COMPLAINT";
    if (state.dropDownValue === "generalQuery")
      purposeVal = "GENERAL_QUERY";
    if (state.dropDownValue === "others")
      purposeVal = "OTHER";
    var payload = {
      purpose: purposeVal,
      name: state.nameValue,
      emailId: state.emailAddress,
      mobileNumber: state.mobileNumber,
      campaignName: state.campaignName,
      query: state.tellUsMore
    };
    if(!error.errStatus){
      fetch("/feedback/customer/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      })
        .then(response => {
          if (response.status === 200)
            handleClickOpen();
        })
        .catch(exception => {
          console.log(exception);
        });
    }
    event.preventDefault();
  };

  const { dropDownValue, nameValue, emailAddress, campaignName, mobileNumber, tellUsMore } = state;
  return (

    <div>
      <DialogCommon handleClose={handleClose} propsVal={state} openVal={open} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h3" variant="h3">{intl.formatMessage({ id: "contactus.please.fill" })}</Typography>
          <form onSubmit={handleSubmitForCustomerFeedBack} className={classes.form}>
            <TextField
              className={classes.imputField}
              onChange={e => handleInput(e, "nameValue")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label={intl.formatMessage({ id: 'contactus.form.customer.name' })}
              name="name"
              color="secondary"
              autoFocus
              value={nameValue}/>
            <span className = {classes.errorMessage}>{error.nameValue}</span>
            <TextField
              onChange={e => handleInput(e, "emailAddress")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={intl.formatMessage({id: "contactus.form.customer.email"})}
              name="email"
              autoComplete="email"
              value={emailAddress}
            />
            <span className={classes.errorMessage}>{error.emailAddress}</span>

            <TextField
              onChange={e => handleInput(e, "mobileNumber")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="mobileNumber"
              label={intl.formatMessage({id: "contactus.form.customer.mobile"})}
              id="mobileNumber"
              value={mobileNumber}
            />
            <span className={classes.errorMessage}>{error.mobileNumber}</span>
            <TextField
              onChange={e => handleInput(e, "campaignName")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="campaignName"
              label={intl.formatMessage({id: "contactus.form.customer.campaignName"})}
              id="campaignName"
              value={campaignName}
            />
            <span className={classes.errorMessage}>{error.campaignName}</span>
            {/* Purpose of contacting section starts here */}
            <div>
            <FormControl component="fieldset" className={classes.formControl}>
              <Typography component="h3" variant="h3">{intl.formatMessage({ id: "contactus.form.customer.purpose" })}</Typography>
              <RadioGroup
                aria-label="gettingTouch"
                name="gettingTouch"
                className={classes.group}
                value={dropDownValue}
                onChange={handleChangeForRadioButton}
              >
                <FormControlLabel
                  value="generalQuery"
                  control={<Radio />}
                  label={intl.formatMessage({
                    id: "contactus.form.customer.gq"
                  })}
                />
                <FormControlLabel
                  value="complaint"
                  control={<Radio />}
                  label={intl.formatMessage({
                    id: "contactus.form.customer.complaint"
                  })}
                />
                <FormControlLabel
                  value="others"
                  control={<Radio />}
                  label={intl.formatMessage({
                    id: "contactus.form.customer.others"
                  })}
                />
              </RadioGroup>
            </FormControl>
            </div>
            {/* Purpose of contacting section ends here */}

            <TextField onChange={e => handleInput(e, "tellUsMore")}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="tellUsMore"
                label={intl.formatMessage({id: "contactus.form.customer.tellusmore"})}
                id="tellUsMore"
                value={tellUsMore}
              />
              <span className={classes.errorMessage}>{error.tellUsMore}</span>
            <Button type="submit"  fullWidth variant="contained" color="primary" className={classes.submit}>
             {intl.formatMessage({ id: "contactus.form.customer.submit" })}
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

FormForCustomer.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(FormForCustomer);
