import {getCookie,setUserVisitorProfile} from "./cookie";
const queryString = require('query-string');


export function postForAndroid(visitorIdObj,props){
    if(navigator.userAgent.match(/Android|Window/i)){
        var payLoad = {
            visitor: visitorIdObj,
            campaignOfferId:""+props.campaign.id,
            campaignId:props.campaign.campaignId,
            userAction:"CLICKED_PLAY_STORE"
          };
        fetch(
            "/offer/portal/interested",
            {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payLoad)
            }
        )
        .then(function(response) {
            return response.json();
        })
        .then(function(myJson) {
            console.log(JSON.stringify(myJson));
        })
            .catch(error => {
                console.log("failed: Click count for android playstore");
        });
    }
}

export function postForVisitorCount(src,campaignId,shortCode,msisdn){
    var uidValue = getCookie("uid");
    var srcValue = "OTHERS";
    // get source type from query param
    const parsedQueryString = queryString.parse(src);
    const queryKeys = Object.keys(parsedQueryString);
    queryKeys.forEach(function(key) {
      const keysInLowerCase = key.toLowerCase();
      if(keysInLowerCase === "source"){
        srcValue = parsedQueryString[key];
      }
    });

    var payLoad = {
        urlCode: shortCode? shortCode:'',
        uid: uidValue,
        msisdn :msisdn?msisdn:'',
        timeStamp: new Date(),
        userAgent: navigator.userAgent,
        source: srcValue
      };
      fetch(
        "/offer/portal/"+campaignId+"/visitor",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payLoad)
        }
      )
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        setUserVisitorProfile(myJson);
        console.log(JSON.stringify(myJson));
      })
        .catch(error => {
    });
    
}


export function postForIOS(visitorIdObj,props){
    if(navigator.userAgent.match(/iPhone|iPad|iPod/i)){
        var payLoad = {
            visitor: visitorIdObj,
            campaignOfferId:""+props.campaign.id,
            campaignId:props.campaign.campaignId,
            userAction:"CLICKED_APP_STORE"
        };
        fetch(
            "/offer/portal/interested",
            {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payLoad)
            }
        )
        .then(function(response) {
            return response.json();
        })
        .then(function(myJson) {
            console.log(JSON.stringify(myJson));
        })
            .catch(error => {
            console.log("failed: Click count for android playstore");
        });
    }
}

export function acceptAndContinueCount(visitorIdObj,props){
    var payLoad = {
        visitor: visitorIdObj,
        campaignOfferId:""+props.campaign.id,
        campaignId:props.campaign.campaignId,
        userAction:"ACCEPT_AND_CONTINUE"
        };
        fetch(
            "/offer/portal/interested",
            {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payLoad)
            }
        )
        .then(function(response) {
            return response.json();
        })
        .then(function(myJson) {
            console.log(JSON.stringify(myJson));
        })
            .catch(error => {
                console.log("error",error);
        });
}

export function userLikedCountPost(props){
    var visitorId = getCookie("userProfileId");
    var visitorIdObj =  {"id":parseInt(visitorId)};
    var payLoad = {
      visitor: visitorIdObj,
      campaignOfferId:""+props.campGeneratedId,
      campaignId:props.campaignId,
      userAction:"LIKE"
    };

    fetch(
      "/offer/portal/interested",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payLoad)
      }
    )
    .then(function(response) {
      return response.json();
    })
      .catch(error => {
  });
}

