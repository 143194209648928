import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import ShareSocial from "./ShareSocial";
import { injectIntl, intlShape } from "react-intl";
import ThumbUp from "@material-ui/icons/ThumbUpAltOutlined";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import { getCookie } from "../../commons/cookie";
import { userLikedCountPost } from "../../commons/service";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateLikeCount } from "../../actions/offers";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(2),
    marginRight: theme.spacing(3)
  },
  iconColorChecked: {
    color: theme.palette.secondary.main,
    width:"20px"
  },
  iconColor:{
    color:theme.palette.primary.textSecondary,
    width:"20px"
  },
  card: {
    maxWidth: 345,
    minHeight: 345
  },
  cardContent: {
    minHeight: 115
  },
  media: {
    height: 185
  },
  cardAction: {
    display: "block",
    textAlign: "initial",
    width: "100%"
  },
  button: {
    backgroundColor: "white !important",
    transition: "transform .2s",
    "&:hover": {
      transform:
        "scale(1.2)" /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }
  },
  iconTextAlign:{
    display:"flex"
  },
  likeCount: {
    marginBottom: "-8px",
    color: theme.palette.primary.textSecondary,
    fontSize: "16px"
  },
  visibleCount: {
    marginBottom: "-2px",
    color: theme.palette.primary.textSecondary,
    fontSize: "16px"
  },
  shareCount:{
    marginTop: "6px",
    color: theme.palette.primary.textSecondary,
    fontSize: "16px",
    fontWeight:500
  },
  cardWrapper: {
    display: "flex",
    width:"100%"
  },
  cardHeading: {
    overflowWrap: 'break-word'
  },
  countWrapper: {
    float: "right",
    marginTop: "-18px",
    marginRight: "10px",
    color: "#757575"
  }
}));

const getRibbonData = (rewardSize,intl) =>{
  let rewardQuantity;
  if ( /^\d+$/.test( rewardSize)) {  //check if reward size is integer ex: props.rewardSize = 1200
    if (parseInt(rewardSize) > 1000) { // append MB/GB based on calculation
      let toGb = rewardSize / 1000;
      rewardQuantity = `${toGb.toFixed(2)} ${intl.formatMessage({ id: "gb" })}`;
    } else rewardQuantity = `${rewardSize} ${intl.formatMessage({ id: "mb" })}`;
  }
  else {
    rewardQuantity = rewardSize; // for non integer reward size ex: 100 Rs
  }
  return rewardQuantity;
}
const OfferCard = props => {
  const [likeCount, setLike] = useState(props.likeCount);
  const [shareCount, setShare] = useState(props.shareCount);
  const [viewCount, setViewCount] = useState(props.viewCount);
  const dispatch = useDispatch();
  const IslikedCampaign = props.likedCampaignIds.includes(
    "" + props.campaignId
  );
  let rewardQuantity = "";
  const [isButtonDisabled, setButtonDisabled] = useState(
    props.likedCampaignIds === undefined ? false : IslikedCampaign
  );
  const classes = useStyles();
  const userLikedIt = () => {
    setLike(likeCount + 1);
    setButtonDisabled(true);
    dispatch(updateLikeCount(props.campGeneratedId)); // update Redux
    userLikedCountPost(props);
  };

  React.useEffect(() => {
    let likeValue = (props.likeCount >9999)?`${(likeCount/10000).toFixed(1)}K`: props.likeCount;
    let shareeValue = (props.shareCount >9999)?`${(shareCount/10000).toFixed(1)}K`: props.shareCount;
    let viewValue = (props.viewCount >9999)?`${(viewCount/10000).toFixed(1)}K`: props.viewCount;
    setLike(likeValue);
    setShare(shareeValue);
    setViewCount(viewValue);
  }, [props]);

  const calibrateShareCount = () => {
    setShare(shareCount + 1);
  };

  const userKnewMore = () => {
    let ref = "/";
    if (props.campaignId) {
      props.history.push(ref + props.campaignId);
    }
    return <Redirect to={ref} />;
  };
  rewardQuantity = getRibbonData(props.rewardSize,props.intl);
  return (
      <Card container>
          <ButtonBase className={classes.cardAction} onClick={userKnewMore}>
              <CardMedia className={classes.media} image={props.image}>
                  {/* ribbon starts here */}
                  <div class="ribbon" dir={"ltr"}>
                      <div class="glow">&nbsp;</div>
                      <i class="fa fa-gift fa-2x" aria-hidden="true">
                          &nbsp;
                      </i>
                      <span>{} </span>
                  </div>
                  {/* ribbon ends here */}
              </CardMedia>
              <CardContent className={classes.cardContent}>
                  <Typography variant="h4" className={classes.cardHeading}>
                      {props.name}
                  </Typography>
                  <Typography variant="h5" component="p">
                      {props.desc}
                  </Typography>
              </CardContent>
          </ButtonBase>
          <Typography
              variant="h6"
              color="textSecondary"
              component="p"
              className={classes.countWrapper}
              //className={classes.heading}
          >
              {likeCount} {props.intl.formatMessage({ id: "likes" })} |{shareCount}{" "}
        {props.intl.formatMessage({ id: "share" })} |{viewCount}{" "}
        {props.intl.formatMessage({ id: "views" })}
          </Typography>

          {/* like share and view actions starts here */}
          <CardActions className={classes.cardWrapper}>
              {isButtonDisabled ? (
                  <Button
                      disabled={true}
                      size="small"
                      onClick={userLikedIt}
                      className={classes.button}
                  >
                      <div className={classes.iconTextAlign}>
                          <ThumbUp className={classes.iconColorChecked} />
                          <span className={classes.likeCount}>
                              {"\u00A0"}
                              {props.intl.formatMessage({ id: "like" })}
                          </span>
                      </div>
                  </Button>
              ) : (
                  <Button
                      onClick={userLikedIt}
                      size="small"
                      className={classes.button}
                  >
                      <div className={classes.iconTextAlign}>
                          <ThumbUp className={classes.iconColor} />
                          <span className={classes.likeCount}>
                              {"\u00A0"}
                              {props.intl.formatMessage({ id: "like" })}
                          </span>
                      </div>
                  </Button>
              )}

              <div className={classes.iconTextAlign}>
                  <ShareSocial
                      shareCountFun={calibrateShareCount}
                      shareCount={props.shareCount}
                      campGeneratedId={props.campGeneratedId}
                      campaignId={props.campaignId}
                      shareUrl={props.shareUrl}
                      quote={props.quote}
                  />
                  <span className={classes.shareCount}>
                      {"\u00A0"}
                      {!props.displayCount
                          ? props.intl.formatMessage({ id: "share" })
                          : ""}
                  </span>
              </div>
              <Button
                  onClick={userKnewMore}
                  size="small"
                  className={classes.button}
              >
                  <div className={classes.iconTextAlign}>
                      <Visibility className={classes.iconColor} />
                      <span className={classes.visibleCount}>
                          {"\u00A0"}
                          {props.intl.formatMessage({ id: "view" })}
                      </span>
                  </div>
              </Button>
          </CardActions>
          {/* like share and view actions ends here */}
      </Card>
  );
};

OfferCard.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(OfferCard);
