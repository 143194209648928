import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { injectIntl, intlShape } from "react-intl";
import Button from '@material-ui/core/Button';

import OfferDetail from '../modules/offerDetails/OfferDetailLayout'



const useStyles = makeStyles(theme => ({
    imageContainer: {
        backgroundImage: "linear-gradient(to right top, #d62238, #9f1c35, #69192c, #36131c, #000000)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100vh",
        position: "fixed",
        left: '0',
        right: '0',
        bottom: '0',
        top: '0'
    },
    consentWrapper: {
        width: "80%",
        margin: "auto",
        padding: "6%",
        position: "relative",
        textAlign:"center"
    },
    consentText: {
        fontSize: '44px',
        fontWeight: 'bold',
        color: "white"
    },
    buttonWrapper: {
        height: '55px',
        fontSize: '20px',
        margin: '10px'
    }


}));

const ConsentView = props => {
    const classes = useStyles();
    const theme = useTheme();
    const [state, setState] = useState({ consentText: 'consentMsg', showScreen: '', showConsent: props.campaign.ageBasedVisitorCheckRequired});
    const handleConsent = (value) => {
        value === 'showCards' ? setState({ ...state, showConsent: false }) : setState({ ...state, consentText: 'consentFailureMsg' })
    }

    let showConsentScreen = (<div>
    <div className={classes.imageContainer}/>
    <div className={classes.consentWrapper}>
    <img src={theme.logoPath} className ={classes.logoImage} alt="Logo" />
        <p className={classes.consentText}>{props.intl.formatMessage({id: state.consentText})}</p>
      <Button color="primary" className={classes.buttonWrapper} onClick={(e)=>handleConsent("failed")}>
        {props.intl.formatMessage({ id: "disagreeToConsent" })}
      </Button>
      <Button color="primary" className={classes.buttonWrapper} onClick={(e)=>handleConsent("showCards")}>
        {props.intl.formatMessage({ id: "agreeToConsent" })}
      </Button>
    </div></div>)


      let offerDetailScreen=  < OfferDetail campaign = { props.campaign } urlCode = { props.urlCode } queryString = { props.queryString } />
    return (
        <div>
        {
            state.showConsent ?<div>{showConsentScreen}</div>:<div>{offerDetailScreen}</div>
        }
        </div>
    )
}


export default injectIntl(ConsentView);