export function getAppUrlBasedOnOS(props){
    var targetUrl = "";
    if(navigator.userAgent.match(/Android|Window/i)){
        targetUrl = props.campaign.androidAppUrl;
    }
    if(navigator.userAgent.match(/iPhone|iPad|iPod/i)){
        targetUrl = props.campaign.iosAppUrl;
    }
    return targetUrl;
}

export function convertFromHttpToHttps(targetUrl,urlCode){
    if (targetUrl) {
        if (!targetUrl.includes("http")) {
            targetUrl = "https://" + targetUrl;
        }
        targetUrl = targetUrl.replace("$CODE", urlCode);
    }
    return targetUrl;
}

export function getDirection (language){
    return (language === "fa-FA" || language === "ar-JO") ? "rtl" : "ltr";
}
