import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { injectIntl, intlShape } from "react-intl";
import DialogCommon from "./DialogCommon";
import {validate} from './validation'
import { useSelector, useDispatch } from "react-redux";
import SwitchBasedLanguageChange from './SwitchBasedLanguageChange'
import OtpInput from "react-otp-input";
import InputLabel from "@material-ui/core/InputLabel";
import MySnackbarContentWrapper from './toast'
import Snackbar from '@material-ui/core/Snackbar';

const queryString = require('query-string');

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    marginTop: theme.spacing(3)
  },
  group: {
    margin: theme.spacing(1, 0)
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  errorMessage: {
    color: 'red'
  },
  logoImage:{
    marginLeft:"35%"
  },
  changeLanguageWrapper:{
    float:"right"
  },
  pageErrorMessage:{
    color: 'red',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: "25px"
  }

}));

function inputFieldValidation(mobileRegex, pincodeRegex, setError, error, intl, mobileDigit) {
  return (value, input) => {
    let regex = '';
    switch (input) {
      case "mobileNumber":
        regex = mobileRegex;
        break;
      case 'pincode':
        regex = pincodeRegex;
        break;
      default: regex = '';
    }
    const res = validate(input, value, regex);
    const name = res.name;
    if (!res.formIsValid) {
      setError({ ...error, [name]: intl.formatMessage({ id: res.errMsg }, { digit: mobileDigit }), ['errStatus']: true });
    }
    else {
      setError({ ...error, [name]: '', ['errStatus']: false });
    }
  };
}


function validateForm(error, statesToBeIgnored, isFormValid) {
  for (let key in error) {
    if (error[key] !== '' && !statesToBeIgnored.includes(key)) {
      isFormValid = false;
    }
  }
  return isFormValid;
}

const CustomerInfoForm = ({ intl }) => {
  const classes = useStyles();
  const config = useSelector((storeState) => storeState.config);
  const mobileRegex = config.config.mobileRegex;
  const mobileDigit = config.config.mobileDigit;
  const pincodeRegex = config.config.pincodeRegex;
  const parsedQueryString = queryString.parse(window.location.search); // fetch email from query param
  const decodedEmail = parsedQueryString.e?atob(parsedQueryString.e):'';
  const decodeWinnerId = parsedQueryString.i?atob(parsedQueryString.i):'';
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [error, setError] = useState({ address:'',emailAddress : '',errStatus:false, nameValue:'',pincode:'',city:'',otp: '' });
  const [state, setState] = useState({ address:'',dropDownValue: "generalQuery", nameValue: "", emailAddress:decodedEmail, city: "", pincode: "",otp: "", feedBackApiResponse: "", snackbarVariant: ""  });
 
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setState({ dropDownValue: "generalQuery", nameValue: "", city: "", pincode: "", address: "" })
  };

  function handleCloseSnack(event, reason) {
    if (reason === 'clickaway') {
        return;
    }
    setOpenSnack(false);
}

  const checkForFieldValidation = inputFieldValidation(mobileRegex, pincodeRegex, setError, error, intl, mobileDigit)

  function handleInput(event, input) {
    setState({ ...state, [input]: event.target.value });
    checkForFieldValidation(event.target.value, input);
  }

  function handleInputOtp(value, input) {
    setState({ ...state, [input]: value });
    checkForFieldValidation(value, input);
  }

  const showSnackBar = (message, variant) => {
    setState({...state, snackbarVariant: variant,  feedBackApiResponse: message});
    setOpenSnack(true);
  }


  const handleSubmitForCustomerFeedBack = event => {
    var payload = {
      name: state.nameValue,
      email: state.emailAddress,
      // mobile: state.mobileNumber,
      addressLine1: state.address,
      city: state.city,
      pinCode: state.pincode,
      otp: state.otp,
      userIdentity: "EMAIL"
    };

    let statesToBeIgnored = ["errStatus"];
    var isFormValid = true;
    isFormValid = validateForm(error, statesToBeIgnored, isFormValid);

    if(!error.errStatus && isFormValid){
      fetch(`/collect-info/${decodeWinnerId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      })
        .then( response => {
          if (response.status === 200) {
            handleClickOpen();
            showSnackBar("successFullyAdded", "success");
          }
        else{
            response.json().then( (responseData) => {
            showSnackBar(responseData.message, "error");
          })
        }
        })
        .catch(exception => {
          showSnackBar("somethingWrong", "error");
          console.log(exception);
        });
    } else {
      showSnackBar("fieldvalidationErrmsg", "error");
    }
    event.preventDefault();
  };

  const { nameValue, emailAddress, city, pincode,address } = state;
  if(decodedEmail === '' || decodeWinnerId === '') {
    return (
      <div>
        <div className={classes.changeLanguageWrapper}>
          <SwitchBasedLanguageChange/>
        </div>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div >
          <img src={theme.logoPath} className ={classes.logoImage} alt="Logo" />
        </div>
        <p className ={classes.pageErrorMessage}>{intl.formatMessage({ id: "pageNotFound"})}</p>
        </Container>
      </div>
    )
  }
  return (

    <div>
      <div className={classes.changeLanguageWrapper}>
      <SwitchBasedLanguageChange/>
      </div>
      <DialogCommon handleClose={handleClose} propsVal={state} openVal={open} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div >
          <img src={theme.logoPath} className ={classes.logoImage} alt="Logo" />
          <Typography component="h3" variant="h3">{intl.formatMessage({ id: "contactus.please.fill" })}</Typography>
          <form onSubmit={handleSubmitForCustomerFeedBack} className={classes.form}>
            <TextField
              className={classes.imputField}
              onChange={e => handleInput(e, "nameValue")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label={intl.formatMessage({ id: 'contactus.form.customer.name' })}
              name="name"
              color="secondary"
              autoFocus
              value={nameValue}/>
            <span className = {classes.errorMessage}>{error.nameValue}</span>
            <TextField
              className={classes.imputField}
              onChange={e => handleInput(e, "address")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="address"
              label={intl.formatMessage({ id: 'address' })}
              name="address"
              color="secondary"
              autoFocus
              multiline
              rows={4}
              value={address}/>
            <span className = {classes.errorMessage}>{error.address}</span>
            <TextField
              onChange={e => handleInput(e, "city")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="city"
              label={intl.formatMessage({id: "city"})}
              id="city"
              value={city}
            />
            <span className={classes.errorMessage}>{error.city}</span>

            <TextField onChange={e => handleInput(e, "pincode")}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="pincode"
                label={intl.formatMessage({id: "pincode"})}
                id="pincode"
                value={pincode}
              />
              <span className={classes.errorMessage}>{error.pincode}</span>
              {/* <TextField
              onChange={e => handleInput(e, "mobileNumber")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="mobileNumber"
              label={intl.formatMessage({id: "contactus.form.customer.mobile"})}
              id="mobileNumber"
              value={mobileNumber}
            />
            <span className={classes.errorMessage}>{error.mobileNumber}</span> */}
            <TextField
              onChange={e => handleInput(e, "emailAddress")}
              variant="outlined"
              margin="normal"
              required
              InputProps={{
                readOnly: true
              }}
              fullWidth
              id="email"
              label={intl.formatMessage({id: "contactus.form.customer.email"})}
              name="email"
              autoComplete="email"
              value={emailAddress}
            />
            <span className={classes.errorMessage}>{error.emailAddress}</span>
            {/* {askOtp? */}
            <div>
              <br/>
              <InputLabel id="otpLabel">
              {intl.formatMessage({ id: "enterOtp" }, { emailId: state.emailAddress })}
            </InputLabel>
            <br/>
            <OtpInput
            value={state.otp}
            inputStyle={{width:"35px",height:"35px",margin:"5px"}}
            onChange={e => handleInputOtp(e, "otp")}
            numInputs={4}
            isInputNum={true}
            separator={<span>-</span>}
          />
            <span className={classes.errorMessage}>{error.otp}</span>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
             {intl.formatMessage({ id: "contactus.form.customer.submit" })}
            </Button></div>
            {/* :<Button  onClick = {validateEmail} fullWidth variant="contained" color="primary" className={classes.submit}>
             {intl.formatMessage({ id: "validateEmail" })}
            </Button>} */}
          </form>
          <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
            >
                <MySnackbarContentWrapper
                    onClose={handleCloseSnack}
                    variant={state.snackbarVariant}
                    message={state.feedBackApiResponse}
                />
            </Snackbar>
        </div>
      </Container>
    </div>
  );
};

CustomerInfoForm.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(CustomerInfoForm);