import React, { useState } from 'react';
import { injectIntl } from "react-intl";
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const congratulationImage = './image/congratulation.jpg'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "10px"
    },
    winnerImage: {
        backgroundImage: `url(${congratulationImage})`,
        backgroundSize: "cover",
        height: "270px"
    },
    table: {
        maxWidth: '100vw',
      },
}));


function WinnersList(props) {
    const classes = useStyles();


    function createData(mobile, reward,date) {
        return { mobile, reward,date };
      }
    const rows = [
        createData('944****785', 'LG Smart TV-32 Inch ', 'Week 1'),
        createData('890****725', 'Samsung Smartphone', 'Week 1'),
        createData('998****915', 'LG Smart TV-32 Inch ', 'Week 2'),
        createData('899****945', 'Samsung Smartphone', 'Week 2'),
        createData('838****675', 'LG Smart TV-32 Inch ', 'Week 3'),
        createData('908****905', 'Samsung Smartphone', 'Week 3')
      ];
    return (
        <div className={classes.root}>
            <div className={classes.winnerImage} />
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{props.intl.formatMessage({ id: "mobile" })}</TableCell>
                            <TableCell >{props.intl.formatMessage({ id: "reward" })}</TableCell>
                            <TableCell >{props.intl.formatMessage({ id: "week" })}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.mobile}
                                </TableCell>
                                <TableCell >{row.reward}</TableCell>
                                <TableCell >{row.date}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}


export default injectIntl(WinnersList);
