//setting the initialState
import themes from "../infinityTheme";

const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : themes.languages[0].local
const intialStates = {
  offersData: [],
  offers: [],
  language: lang,
  campaignInfo: {}
};

export const offers = (state, action) => {
  //set to default state if no state is been found
  if (!state) {
    state = intialStates;
  }
  const newState = { ...state };
  switch (action && action.type) {
    case "UPDATE_LIST": {
      newState.offers = [...action.payload];
      newState.offersData = JSON.parse(JSON.stringify(action.payload)); //deep copy of action.payload
      return newState;
    }
    case "CAMPAIGN_INFO": {
      let newStateCP = { ...state };
      newStateCP.campaignInfo = action.payload;
      return {
        ...newStateCP
      };
    }
    case "SEARCH_RESULT": {
      const offersOnSearch = [...action.payload];
      state.offers = offersOnSearch;
      return {
        ...state
      };
    }
    case "SORT_CAMPAIGN": {
      newState.offers = action.payload;
      return newState;
    }
    case "UPDATE_LANGUAGE": {
      let languageState = { ...state };
      languageState.language = action.payload;
      return {
        ...languageState
      };
    }
    case "UPDATE_LIKE_COUNT": {
      let newStateLike = { ...state };
      newStateLike.offers.map(key => {
        if (key.id === action.payload) {
          key.likeCount++;
        }
      });
      return {
        ...newStateLike
      };
    }
    default:
      return {
        ...state
      };
  }
};
