const ApiErrorHandler = async (apiResult) => {
    let jsonResponse = await apiResult.json();
    if (apiResult.status === 200) {
        return jsonResponse.message;
    }
    else if (apiResult.status === 400) {
        return jsonResponse.errors[0];
    }
    return "Error"

}

export default ApiErrorHandler;