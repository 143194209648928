import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { injectIntl } from "react-intl";
import Container from "@material-ui/core/Container";
import { validate } from '../../commons/validation';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSelector } from "react-redux";
import ReCaptcha from "../commons/captcha";
import Loader from '../commons/Loader';
import Survey from '../../commons/Survey';
import Modal from '@material-ui/core/Modal';
import ApiErrorHandler from '../../commons/apiErrorHandler'
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing(1),
  },
  logo: {
    textAlign: "center",
    marginTop: "10px"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  },
  card: {
    marginTop: "20px",
    maxWidth: 400,
    direction:"ltr"
  },
  submit: {
    marginTop: "15px",
    marginLeft: "10px",
    label: {
      color: "#ffffff"
    }
  },
  errorMessage: {
    color: 'red',
    marginLeft: '8px'
  },
  errorMessageMain: {
    marginTop: "15px",
    color: 'blue',
    float: "left",
    marginBottom: "10px",
    marginLeft: "12px",
  },
  modalContainer:{
    backgroundColor:"white",
    top: '50%',
    left: '50%',
    transform: `translate(0%, 165%)`,
    padding:"10px"
  },
  modalApiResponseMessage: {
    marginTop: "15px",
    fontSize: "17px",
    color: 'blue',
    textAlign:"center",
    marginBottom: "10px"
  },
  participateAgainButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    //   border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
}
}));


function fetchRegexAndDigit(props, couponRegex, config, couponDigit,inputFormat,inputSeparator) {
  if (props.codeFormat && props.codeDigits) {
     if(props.codeFormat === "NUMERIC" &&  inputFormat === "CODE_AND_USER_CHOICE"){
      const regexSpecialChar = ['[', "\\","^","$",".","|","?","*","+","(",")"]
      let seperator = regexSpecialChar.indexOf(inputSeparator) === -1? inputSeparator:`\\${inputSeparator}`;
      couponRegex = `^\\d{14}${seperator}\\d{1}$`
      couponDigit = props.codeDigits +2;

    }
    else  if (props.codeFormat === "NUMERIC") {
      couponRegex = config.config.numericCoupon;
    couponDigit = props.codeDigits;

    }
    else {
      couponRegex = config.config.alphaNumericCoupon;
      couponDigit = props.codeDigits;
    }
  }
  else {
    couponRegex = config.config.couponRegex;
    couponDigit = config.config.couponDigit;
  }
  return { couponRegex, couponDigit };
}

const getValidValue = (expression, resultValue1, resultValue2)=>{
  return expression? resultValue1:resultValue2;
}

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
  };
}

function ShowParticipationResponse(props) {
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const participationResponse = props.apiResponse;

  const handleClose = () => {
      setOpen(false);
  };
  return (
      <div>
          <Modal
              open={open}
              onClose={handleClose}
              disableBackdropClick={true}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
          >
          <div className={classes.modalContainer}>
              <p className={classes.modalApiResponseMessage}>{participationResponse}</p>
              <br/>
              <Button color="primary" className={classes.participateAgainButton} onClick={props.closeModal}>
                        {props.intl.formatMessage({id : "participateAgain"})}
              </Button>
          </div>
          </Modal>
      </div>
  )
}
function handleAllInputFields(setState, state, mobileRegex, mobileDigit, couponRegex, couponDigit, campaignInfo, setError, error, props) {
  return (event, input) => {
    const value = event.target.value;
    let inputValue, regex, digit;
    setState({ ...state, [input]: event.target.value });
    switch (input) {
      case "mobileNumber": {
        inputValue = value;
        regex = mobileRegex;
        digit = mobileDigit;
        break;
      }
      case "coupon": {
        inputValue = value;
        regex = couponRegex;
        digit = couponDigit;
        break;
      }
      default: break;
    }

    const res = validate(input, inputValue, regex, couponDigit);
    const name = res.name;
    let errorMsg = (input === "coupon" && campaignInfo.inputFormat === "CODE_AND_USER_CHOICE") ? "couponErrorWithUserInput" : res.errMsg;
    if (!res.formIsValid) {

      setError({ ...error, [name]: props.intl.formatMessage({ id: errorMsg }, { digit: digit, couponDigit: digit - 2, seperator: campaignInfo.inputSeparator }), ['errStatus']: true });
    }
    else {
      setError({ ...error, [name]: '', ['errStatus']: false });
    }

  };
}
function submitClickHandler(askRedeemScreenMobile, state, countryCode, error, mandatorySurveyAndCouponValidation, setShowLoader, props, couponValidateUrl, showApiResponse, setShowSurvey, redeemCouponAPiCall) {
  return event => {
    let validatePhoneNumber = askRedeemScreenMobile ? state.mobileNumber.length > countryCode.length : true;
    if (!error.errStatus && (validatePhoneNumber && state.coupon.length !== 0)) {
      if (mandatorySurveyAndCouponValidation) {
        setShowLoader(true);
        let payLoad = {
          code: state.coupon, supplierId: "S_001",
          campaignId: getValidValue(props.campaignId, props.campaignId, '')
        };
        fetch(
          couponValidateUrl,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify(payLoad)
          }
        )
          .then(function (result) {
            setShowLoader(false);
            if (result.status === 404 || result.status === 400) {
              // setResponse(props.intl.formatMessage({ id: "notValidCode" }));
              showApiResponse(props.intl.formatMessage({ id: "notValidCode" }));
            }
            else {
              setShowSurvey(true);
            }
          })
          .catch((e) => {
            setShowLoader(false);
            //  let error = (state.mobileNumber !== 0 && state.coupon.length !== 0) ? "fillDetail":"somethingWrong"
            // setResponse(props.intl.formatMessage({ id: "somethingWrong" }));
            showApiResponse(props.intl.formatMessage({ id: "somethingWrong" }));
            console.log(`%cerror in redeeming code>>>>>>>>>>>>>>>>>>> ${e}`, 'color: white; background-color: red; font-size: 20px; padding: 3px;');
          });
      }
      else {
        redeemCouponAPiCall();
      }
    }
    else {
      // setResponse(props.intl.formatMessage({ id: "fillDetail" }));
      showApiResponse(props.intl.formatMessage({ id: "fillDetail" }));
    }
    event.preventDefault();
  };
}

const RedeemCoupon = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  let couponRegex, couponDigit;
  const config = useSelector(state => state.config);
  const [showLoader,setShowLoader] = useState(false);
  const campaignInfo = useSelector(state => state.offers.campaignInfo);
  const showCaptcha = config.config.showCaptcha;
  const mandatorySurveyAndCouponValidation = config.config.mandatorySurveyAndCouponValidation;
  const locale = useSelector(state => state.offers.language);
  const countryCode = config.config.countryCode;
  const mobileDigit = config.config.mobileDigit;
  const mobileRegex = config.config.mobileRegex;
  const supplierId = config.config.supplierId;
  const askRedeemScreenMobile = config.config.askRedeemScreenMobile;
  const couponValidateUrl = config.config.couponValidateUrl
  const couponRedeemUrl = config.config.couponRedeemUrl;
  const surveyQuestions= config.config.surveyQuestions||[];
  const [showRedeem, setShowRedeem] = useState(false);
  const [showSurvey, setShowSurvey] = useState (false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [error, setError] = useState({ coupon: '', mobileNumber: '', errStatus: false ,name:'',email:''});
  const [state, setState] = useState({ coupon: "", mobileNumber:''});
  const [response, setResponse] = useState('');


  useEffect(() => {
    if(!showCaptcha){setShowRedeem(true)}
    }, []);

  const handleCloseModal = () => {
    setShowResponseModal(false);
  }

  // pick coupon code /coupon digit from backend or config file
  ({ couponRegex, couponDigit } = fetchRegexAndDigit(props, couponRegex, config, couponDigit,campaignInfo.inputFormat,campaignInfo.inputSeparator));

 // Handle input fields
  const handleInput = handleAllInputFields(setState, state, mobileRegex, mobileDigit, couponRegex, couponDigit, campaignInfo, setError, error, props);

  const showApiResponse = (message) =>{
    //setResponse(message);
    // Temporary Fix: Revert after DI-5512 is closed.
    setResponse(props.intl.formatMessage({ id: message}));
    setShowResponseModal(true);
    setState({ coupon: "", mobileNumber: "" });
  }

   // send coupon redeem details
   const redeemCouponAPiCall = (surveyInfo='',surveyQuestionAnswer='') => {
      setShowLoader(true);
      const countryCodeNoPlus = countryCode.split('+').join('');
      let phoneNumber = askRedeemScreenMobile?(countryCodeNoPlus+state.mobileNumber):'';
      let url = getValidValue(props.participationServiceUrl,props.participationServiceUrl,couponRedeemUrl);
      const payLoad = {
        code: state.coupon,
        campaignId: getValidValue( props.campaignId,props.campaignId,''),
        sender: phoneNumber,
        channelType: "PORTAL",
        supplierId: supplierId,
        responseAsMessage: false,
        transactionId:surveyQuestionAnswer,
        termsCondition: surveyInfo.termsCondition,
        futurePromotion: surveyInfo.futurePromotion,
        preferredLanguage: locale,
        user : {
          mobile: phoneNumber,
          name:surveyInfo.nameValue,
          email: surveyInfo.emailAddress
           }
      };
      // holds good for only nginx configuration
      fetch(
        url,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payLoad)
        }
      )
        .then(function (result) {
          setShowLoader(false);
          let apiResponse= ApiErrorHandler(result);
          showApiResponse(apiResponse)
        })
        .catch((e) => {
          setShowLoader(false);
          //  let error = (state.mobileNumber !== 0 && state.coupon.length !== 0) ? "fillDetail":"somethingWrong"
          // setResponse(props.intl.formatMessage({ id: "somethingWrong" }));
          showApiResponse(props.intl.formatMessage({ id: "somethingWrong" }));
          console.log(`%cerror in redeeming code>>>>>>>>>>>>>>>>>>> ${e}`,'color: white; background-color: red; font-size: 20px; padding: 3px;');

        })
  };

  // to hide survey modal, pass this as props to child component
  const disableSurvey = (surveyInfo) =>{
    setShowSurvey(false);
    redeemCouponAPiCall(surveyInfo,surveyInfo.surveyDetail);
  }
  const handleSubmitForRedemption = submitClickHandler(askRedeemScreenMobile, state, countryCode, error, mandatorySurveyAndCouponValidation, setShowLoader, props, couponValidateUrl, showApiResponse, setShowSurvey, redeemCouponAPiCall)
  const onCaptchaLoad = () => {
    console.log("Captcha loaded!!!!!!!");
  };

  const updateShowRedeem = () => {
    setShowRedeem(true);
  };

  const expiredCallback = () => {
    setShowRedeem(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <Card className={classes.card}>
        <CardActionArea>

          {/* card media */}
          {/* {props.showMedia? <CardMedia
          component="img"
          alt="celebrate"
          height="225"
          image="/image/rewards.png"
          title="Contemplative Reptile"
        /> :""} */}


          {/* card media: logo*/}
          {getValidValue((props.showMedia === false),'',(<div className={classes.logo}>
            <img src={theme.logoPath} alt="Logo" /></div>))}

          <CardContent>
            <Typography gutterBottom variant="h4" component="h4">
              {props.intl.formatMessage({ id: "couponRedemption" })}
            </Typography>
            <form className={classes.form} onSubmit={handleSubmitForRedemption}>
              <TextField
                id="filled-name"
                label={props.intl.formatMessage({ id: 'couponCode' })}
                className={classes.textField}
                value={state.coupon}
                onChange={e => handleInput(e, "coupon")}
                margin="normal"
                fullWidth
                type={getValidValue((props.codeFormat === "NUMERIC" &&  campaignInfo.inputFormat !== "CODE_AND_USER_CHOICE"),"number","")}
              />
              <span className={classes.errorMessage}>{error.coupon}</span>
              {askRedeemScreenMobile?
              <div><TextField
                      className={classes.margin}
                      fullWidth
                      id="filled-name"
                      label={props.intl.formatMessage({ id: "contactus.form.customer.mobile" })}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {countryCode}
                          </InputAdornment>
                        ),
                      }}
                      value={state.mobileNumber}
                      onChange={e => handleInput(e, "mobileNumber")}
                    />
              <span className={classes.errorMessage}>{error.mobileNumber}</span></div>
              :<span/>}
              {/* captcha */}
              {showCaptcha ?<ReCaptcha onloadCallback={onCaptchaLoad} verifyCallback={updateShowRedeem} expiredCallback={expiredCallback}/>:<span/>}
              {/* redeem button */}
              {getValidValue(showRedeem,(<Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {props.intl.formatMessage({ id: "redeem" })}
              </Button>),<span/>)}
              {/* <div className={classes.errorMessageMain}>{response}</div> */}
              {showResponseModal?<ShowParticipationResponse closeModal={handleCloseModal} apiResponse={response} {...props}/> : <span/> }
            </form>
            {showSurvey? <Survey surveyQuestions={surveyQuestions} disableSurvey={(e)=>disableSurvey(e)}/>:<span/>}
          </CardContent>
        </CardActionArea>
      </Card>
      {getValidValue(showLoader,<Loader/>,<span/>)}
    </Container>
  );
};
export default injectIntl(RedeemCoupon);