/* OfferCardsLayout: layout to display the campaign card
   displaying campaigns and displaying sorted campains by mapping the redux state
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import Grid from "@material-ui/core/Grid";
import OfferCard from "./OfferCard";
import { useSelector, useDispatch } from "react-redux";
import { sortCampaign } from '../../actions/offers'
import SelectOption from '../../commons/select'
import { injectIntl, intlShape } from "react-intl";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  content: {
    flexGrow: 1,
    margin:0
  }
}));

const resolveFieldValue = (item,fieldName,offers) => {
   var value = (item.offerDetails[offers.language] && item.offerDetails[offers.language][fieldName])  ? item.offerDetails[offers.language][fieldName]:"";
   return value;
}

const OfferCardProps = (item,props,offers) =>{
  return (<OfferCard name={resolveFieldValue(item,"title",offers)}
                desc={resolveFieldValue(item,"offerDescription",offers)}
                image={resolveFieldValue(item,"offerLogoUrl",offers)}
                shareUrl={item.shareUrl}
                quote={resolveFieldValue(item,"shareTitle",offers)}
                campGeneratedId={item.id} likeCount={item.likeCount} campaignId={item.campaignId} shareCount={item.shareCount}
                viewCount={item.knowMoreCount} likedCampaignIds={props.likedCampaignIds} rewardSize = {item.reward} history={props.history}/>);
}

const OfferCardsLayout = (props) => {
  const classes = useStyles();
  const selectOptions = [
    {id : 0, name :props.intl.formatMessage({id: 'default'})},
    {id : 1, name :props.intl.formatMessage({id: 'popularity'})},
    {id : 2, name :props.intl.formatMessage({id: 'newArrival'})},
    {id : 3, name :props.intl.formatMessage({id: 'rewardSize'})}
    ];
  const dispatch = useDispatch();
  const offers = useSelector(state => state.offers);
  const searchResult = (offers.offers && offers.offers.length > 0) ? JSON.parse(JSON.stringify(offers.offers)) :  JSON.parse(JSON.stringify(offers.offersData)) ;
  /* 
  *  handleSelect :  sort campaigns based on popularity, Newest arrival, reward size and default
  *  accepts id (from variable selectOptions, id-numaric mapping, name-sort option) returns an array of object by dispatching an action to update the redux state
  */

  const handleDropDownChange = (selectedOption,allOffers) =>{
    switch(selectedOption){
      case 1:{
        searchResult.sort((a, b) => {
          return  (b.knowMoreCount + b.shareCount + b.likeCount) - (a.knowMoreCount + a.shareCount + a.likeCount)
        })
        dispatch(sortCampaign(searchResult));
      }break
      case 2 :{
        searchResult.sort(function (a, b) {
          return moment.utc(a.startDate).diff(moment.utc(b.startDate))
        });
        dispatch(sortCampaign(searchResult.reverse()));
      }break
      case 3:{
        searchResult.sort((a, b) => {
          return  (b.reward) -(a.reward)
        })
        dispatch(sortCampaign(searchResult))
      }break
      //default will return the object as it is from api
      default:{
      if(allOffers.offers.length === allOffers.offersData.length)
        dispatch(sortCampaign(allOffers.offersData));
      else 
        dispatch(sortCampaign(allOffers.offers));
      } 
    }
  }
  const handleSelect = (selectedOption) => {
    handleDropDownChange(selectedOption,offers)
  }

  return (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="center" className={classes.content}>
      <SelectOption handleSelect={handleSelect} selectOptions={selectOptions}/>
      <Grid container spacing={2} className={classes.content}>
        {offers && offers.offers.length <= 0 ? <marquee style={{ "fontSize": "30px" }}>{props.intl.formatMessage({id: 'notFound'})}</marquee> :
          offers.offers.length>0 && offers.offers.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} >
              {OfferCardProps(item,props,offers)}
            </Grid>
          ))}
      </Grid>
      </Grid>
    </div>
  );
}
OfferCardsLayout.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(OfferCardsLayout);