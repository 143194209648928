import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        bottom: "0",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        height: "60px",
        justifyContent: 'space-around',
        width: '100%',
        padding: '10px',
        fontSize: 'medium',
        position: 'relative !important',
        boxShadow: ' 0px -15px 10px -15px #111',
    },
    footerTitle:{
        color:"white",
        fontSize:"12px"
    },
    linkStyle:{
        textDecoration:"none"
    }
}));


const Footer = (props) => {
    const theme = useTheme();
    const language = useSelector(state => state.offers.language);
    const classes = useStyles(theme);
    let footerValue = props.footerValue[language];
    let footerList=footerValue.map((footer)=>{
        return <div className={classes.firstOptionContainer}>
        <a href={footer.redirectionLink} target="_blank" rel="noopener noreferrer" className={classes.linkStyle}>
            <span className={classes.footerTitle}>
                &nbsp;{footer.text}
                    </span>
        </a>
    </div>
    })
    return (
        <div className={classes.root}>
            {
                footerList
            }
        </div>
    )

}


export default Footer