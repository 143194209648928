import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { ThemeProvider } from "@material-ui/styles";
import { grey, } from "@material-ui/core/colors";
import InputLabel from "@material-ui/core/InputLabel";
import { injectIntl } from "react-intl";
import MySnackbarContentWrapper from '../../commons/toast'
import Snackbar from '@material-ui/core/Snackbar';


function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        //   border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    optionContainer: {
        display: "flex",
        flexDirection: "column"
    },
    questionContainer: {
        padding: "5px",
        boxShadow: "3px 3px 5px 6px #ccc",
        borderRadius: "10px"

    },
    optionButtons: {
        margin: "5px",
        textTransform: "none"
    }
}));
function PostWatchVideo(props) {

    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(true);
    const surveyQuestions = props.surveyQuestions;
    const [state, setState] = useState({ mobileNumber: '', questionToShow: 0, askNumber: false, referrerMobileNumber: '' });
    const [openSnack, setOpenSnack] = useState(false);
    const handleClose = () => {
        setOpen(false);
        setOpenSnack(true);
    };
    const handleInput = (event, name) => {
        let value = event.target.value;
        setState({ ...state, [name]: value });
    }

    const customButton = createMuiTheme({
        palette: {
            primary: {
                light: grey[800],
                main: grey[800],
                dark: grey[800],
                backgroundColor: grey[800],
            }
        },
        typography: {
            button: {
                textTransform: "none",
                color: "black"
            }
        }
    });
    function handleCloseSnack(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    let handleSurvey = (q) => {
        setState({ ...state, questionToShow: state.questionToShow + 1 })
        console.log("surveyQuestions.length === state.questionToShow", surveyQuestions.length, state.questionToShow)
        if (surveyQuestions.length === state.questionToShow + 1) {
            setState({ ...state, askNumber: true })
        }
    }

    let askNumber = <div >
        <InputLabel id="district-required-label">
            {props.intl.formatMessage({ id: "askMobile" })}
        </InputLabel>
        <br />
        <TextField
            id="mobileNumber"
            name="mobileNumber"
            variant="outlined"
            value={state.mobileNumber}
            onChange={(e) => handleInput(e, "mobileNumber")}
            label="Mobile Number"
            required
            fullWidth

        />
        <br /><br />
        <InputLabel id="district-required-label">
            {props.intl.formatMessage({ id: "referrerMobile" })}
        </InputLabel>
        <br />
        <TextField
            id="referrerMobileNumber"
            name="referrerMobileNumber"
            variant="outlined"
            value={state.referrerMobileNumber}
            onChange={(e) => handleInput(e, "referrerMobileNumber")}
            label="Mobile Number"
            fullWidth

        />
        <br /> <br />
        <Button variant="contained" color="primary" onClick={handleClose}>
            {props.intl.formatMessage({ id: "contactus.form.customer.submit" })}
        </Button>
    </div>

    let question =
        < div className={classes.questionContainer} >
            <InputLabel id="question">
                {surveyQuestions[state.questionToShow].question}
            </InputLabel>
            <br />
            <div className={classes.optionContainer}>
                <ThemeProvider theme={customButton}>
                    <Button variant="outlined" color="primary" className={classes.optionButtons}
                        onClick={handleSurvey}
                    >{surveyQuestions[state.questionToShow].option1}</Button>
                    <Button variant="outlined" color="primary" className={classes.optionButtons}
                        onClick={handleSurvey}>{surveyQuestions[state.questionToShow].option2}</Button>
                    <Button variant="outlined" color="primary" className={classes.optionButtons}
                        onClick={handleSurvey}>{surveyQuestions[state.questionToShow].option3}</Button>
                    <Button variant="outlined" color="primary" className={classes.optionButtons}
                        onClick={handleSurvey}>{surveyQuestions[state.questionToShow].option4}</Button>
                </ThemeProvider>
            </div>
        </div >


    const body = (
        <div style={modalStyle} className={classes.paper}>
            <Typography variant="h4" >
                {props.intl.formatMessage({ id: "thankYouForWatching" })}
            </Typography>
            <br />
            <InputLabel id="question">
                {props.intl.formatMessage({ id: "surveyHeading" })}
            </InputLabel>

            <br /><br />
            {state.askNumber && surveyQuestions.length !== state.questionToShow ? <div>{askNumber}</div> : <div>{question}</div>}
        </div>
    );
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
            >
                <MySnackbarContentWrapper
                    onClose={handleCloseSnack}
                    variant="success"
                    message='watchnWinParticipationMsg'
                />
            </Snackbar>

            <Modal
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}
export default injectIntl(PostWatchVideo);