import React from "react";
import OfferCardsLayout from "./OfferCardsLayout";
import ProductHero from '../views/ProductHero';
import {getCookie} from "../../commons/cookie";
import {postForVisitorCount} from '../../commons/service';
import CustomizedDialogs from '../../commons/intromodal';
import AppMenu from "../commons/AppMenu";
import { connect } from "react-redux";
import { updatelist } from '../../actions/offers'
class OfferDiscoveryHome extends React.Component{
    
    constructor(props) {
        super(props);
        this.state ={
            campaigns : []
        };
    }
    
    componentDidMount() {
      //get the uid of the user. UID is generated once the user lands to the page
        var uidValue = getCookie("uid");
        //discover => it will return all the active campaign to be displayed in the  offer info portal
        fetch('/discover?limit=25')
            .then(results => {
                return results.json();
            }).then((data) => {
            this.props.updatelist(data) //initial values to set
        });
        fetch('/offer/portal/'+uidValue)
            .then(results => {
                return results.json();
            }).then((data) => {
                this.setState({likedCampaignIds: data})
        });
        //get the "source" type  
        // for example, https://offerinfo.infinityzone.com//?code=3456789&source=SOMESOURCE 
        var src =  this.props.location.search;
        postForVisitorCount(src,"HOME");
    }


  render() {
    return(
      <div style={{"flexGrow":"1"}}>
        <AppMenu/>
        <CustomizedDialogs/>
        <ProductHero />
        {this.state.likedCampaignIds &&
       <OfferCardsLayout likedCampaignIds={this.state.likedCampaignIds} history={this.props.history} />}
      </div>
    )
  }
}
const mapStateToProps = state =>
({
  offers: state.offers.offers
});

const mapDispatchToProps = dispatch => ({
  updatelist: (offersList) => {
      dispatch(updatelist(offersList));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(OfferDiscoveryHome);
