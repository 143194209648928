
/*
* Author: Jayalakshmi S
* SearchBar: A function component for type aling search for campaigns
* accepts user input feom search field and seraches in redux state and update the redux state to diaplay campaigns
*/
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { fade, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { useSelector, useDispatch } from "react-redux";
import { searchResult } from "../actions/offers";
import { injectIntl, intlShape } from "react-intl";
import {getDirection} from './utils'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    color: "#fff"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.primary.text}`,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.text

  },
  inputRoot: {
    color: theme.palette.primary.text
  },
  inputInput: {
    textAlign:"center",
    // padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  }
}));

const  SearchBar = ({intl})=> {
  const classes = useStyles();
  const offers = useSelector(state => state.offers);
  const dispatch = useDispatch();
  const language = offers.language;
  const direction = getDirection(language);
 
  const offertFilter = searchText => {
      let lists = JSON.parse(JSON.stringify(offers.offersData)); // Deep copy of redux state
      return lists.filter(list => {
        if(list.offerDetails[language]){
          let tempOffer = list.offerDetails[language];
          let name = tempOffer.title;
          let campaignDescription = tempOffer.offerDescription;
          let searchUpperCase = searchText;
          return (
            name.toUpperCase().match(searchUpperCase.toUpperCase()) ||
            campaignDescription.toUpperCase().match(searchUpperCase.toUpperCase())
          );
        }
         return '';
      });
  };
  //for type along serch feature: searches for campaign name and campaign description
  const handleSearch = e => {
    const userInput = e.target.value;
    if (userInput.length === 0) {
      dispatch(searchResult(offers.offersData));
    } else {
        // serach user input string in redux state
      const lists = offertFilter(userInput.trim());
      dispatch(searchResult(lists));
    }
  };
  return (
    <div className={classes.grow}>
      <div className={classes.search} dir={"ltr"}>
        <div className={classes.searchIcon} >
          <SearchIcon />
        </div>
        <InputBase
          style={{ direction: direction }}
          placeholder={intl.formatMessage({id: 'search'})}
          onChange={e => handleSearch(e)}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          inputProps={{ "aria-label": "search" }}
        />
      </div>
    </div>
  );
}
SearchBar.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(SearchBar);