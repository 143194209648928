import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { getDirection } from './utils'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MySnackbarContentWrapper from './toast'
import Snackbar from '@material-ui/core/Snackbar';
import { updateLanguage } from '../actions/language'
import { injectIntl, intlShape } from "react-intl";


const useStyles = makeStyles(theme => ({
    languageSwitchWrapper:{
        margin:"5px",
        display:"flex",
        flexDirection: 'inherit'
      },
      switchWrapper:{
        left:12
      }

}));

const SwitchBasedLanguageChange = ({ intl }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();


    const [language, setLangugae] = useState(
        useSelector((state) => state.offers.language)
    );
    const [open, setOpen] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [defaultLanguage, setDefaultLanguage] = React.useState((language !== theme.languages[0].local));


    function handleCloseSnack(event, reason) {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnack(false);
      }
    function handleLanguageSelect(language) {
        localStorage.setItem("lang", language);
        const direction = getDirection(language);
        dispatch(updateLanguage(localStorage.getItem("lang")));
        setOpenSnack(true);
        document.getElementsByTagName('Body')[0].style.direction = direction;
        setOpen(false);
    }
    const handleSwitch = (event) => {
        setDefaultLanguage(event.target.checked);
        let languageToChange = defaultLanguage ? theme.languages[0].local : theme.languages[1].local;
        handleLanguageSelect(languageToChange);
    };
    return (
        <div>
            { theme.languages.length === 2 ? <FormGroup className={classes.languageSwitchWrapper}>
                <p>{intl.formatMessage({ id: theme.languages[0].lang })}</p> <FormControlLabel
                    control={<Switch checked={defaultLanguage} className={classes.switchWrapper} onChange={handleSwitch} aria-label="language selection" />}
                // label={defaultLanguage ?intl.formatMessage({ id: theme.languages[0].lang })  : intl.formatMessage({ id: theme.languages[1].lang })}
                />
                <p>{intl.formatMessage({ id: theme.languages[1].lang })}</p>
            </FormGroup> : <span />}
            <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <MySnackbarContentWrapper
            onClose={handleCloseSnack}
            variant="success"
            message='languageChanged'
          />
        </Snackbar>
        </div>
    )
}

export default injectIntl(SwitchBasedLanguageChange);