import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Language from "@material-ui/icons/Translate";
import { useSelector, useDispatch } from "react-redux";
import { updateLanguage } from '../../actions/language'
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Home from "@material-ui/icons/Home";
import ContactPhone from "@material-ui/icons/ContactPhone";
import AppBar from "@material-ui/core/AppBar";
import SearchBar from '../../commons/searchBar'
import { Link } from "react-router-dom";
import { injectIntl, intlShape } from "react-intl";
import MySnackbarContentWrapper from '../../commons/toast'
import Snackbar from '@material-ui/core/Snackbar';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import {getDirection} from '../../commons/utils'
import SwitchBasedLanguageChange from '../../commons/SwitchBasedLanguageChange'

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    top: 0
  },
  menuButton: {
    margin:"0px"
  },
  logoImage:{
    padding:'10px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  portalHeadingTitle:{
    fontSize:"16px",
    fontWeight:"700"
  },
  portalHeading:{
    fontSize:"15px",
    fontWeight:"700"
  },
  languageSwitchWrapper:{
    margin:"5px",
    display:"flex",
    flexDirection: 'inherit'
  },
  switchWrapper:{
    left:12
  }
}));


function getPrimaryMenuItem(menuOption, intl, theme, handleLanguageClick, openCollapse) {
  switch (menuOption) {
    case 'home': return (
      <ListItem button component={Link} to="/">
        <ListItemIcon>
          <Home />
        </ListItemIcon>
        <ListItemText primary={intl.formatMessage({ id: 'appmenu.listitem.home' })} />
      </ListItem>
    );
    case 'contactUs': return (
      <ListItem button component={Link} to="/formContacts" key="9">
        <ListItemIcon>
          <ContactPhone />
        </ListItemIcon>
        <ListItemText primary={intl.formatMessage({ id: 'appmenu.listitem.contactus' })} />
      </ListItem>
    );
      { /* show change language option only if there are more than one language in the theme.js */ }
    case 'language': {
      return theme.languages.length > 1 ?
        <ListItem button onClick={handleLanguageClick} key="language">
          <ListItemIcon><Language /></ListItemIcon>
          <ListItemText primary={intl.formatMessage({ id: 'translate' })} />
          {openCollapse ? <ExpandLess /> : <ExpandMore />}
        </ListItem> : '';
    }
    default: return <span/>

  }
}

const AppMenu = ({ intl }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const config = useSelector((state) => state.config);
  const menuItemFromConfig =  config.config.menuItems;
  const primaryMenuItem = config.config.primaryMenuItem ;
  const dispatch = useDispatch();

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleLanguageClick() {
    setOpenCollapse(!openCollapse);
  }

  function handleCloseSnack(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  }

  function handleLanguageSelect(language) {
    localStorage.setItem("lang", language);
    const direction = getDirection(language);
    dispatch(updateLanguage(localStorage.getItem("lang")));
    setOpenSnack(true);
    document.getElementsByTagName('Body')[0].style.direction = direction;
    setOpen(false);
  }

 

   return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
          <Toolbar>
           { primaryMenuItem.length === 0?<span/>:<IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open
              })}
            >
              <MenuIcon />
            </IconButton>}
            <div>
            </div>
            {/* <img src={theme.logoPath} className ={classes.logoImage} alt="Logo" /> */}
            {window.location.pathname === '/' ?
              <span>
                <SearchBar />
              </span> : ' '
            }
            <SwitchBasedLanguageChange/>
          </Toolbar>
        </AppBar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <MySnackbarContentWrapper
            onClose={handleCloseSnack}
            variant="success"
            message='languageChanged'
          />
        </Snackbar>
        <Drawer
          variant="persistent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          open={open}
        >
          <div className={classes.toolbar}>
            <div className={classes.portalHeading}>
            {intl.formatMessage({ id: 'offerPortalTitle' })}
              {/* <p>{process.env.REACT_APP_GIT_VERSION}</p> */}
            </div>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                  <ChevronLeftIcon />
                )}
            </IconButton>
          </div>
          <Divider />
          {/* primary menuItems */}
          {
            primaryMenuItem.length === 0? '':
            primaryMenuItem.map((menuOption)=>{
              return getPrimaryMenuItem(menuOption, intl, theme, handleLanguageClick, openCollapse);
            })
          }
          {/* collapsablle menu for language */}
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablepadding>
              {theme.languages.map((language, index) => (
                <ListItem button key={index} onClick={handleLanguageSelect.bind(this, language.local, language.lang)}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary={language.lang} />
                </ListItem>))}
            </List>
          </Collapse>
          {/* secondary menu Items */}
          {menuItemFromConfig.length === 0 ? '':
            menuItemFromConfig.map((ele)=>{
             return( <ListItem  button
              component={Link} to={`/customisedView?iframeLink= ${ele.link}`}
               key={ele.title}>
              <ListItemText primary={ele.title} />
            </ListItem>)
            })
          }
        </Drawer>
      </div>
    </ClickAwayListener>
  );
}

AppMenu.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(AppMenu);