import React from "react";
import { makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import ButtonAppBar from "../commons/AppMenu";
import ContactMainGrid from "./ContactMainGrid";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  content: {
    flexGrow: 1
  }
}));


export default function ContactUsForm() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ButtonAppBar />
      <Grid container justify="center" alignItems="center" className={classes.content}>
      <ContactMainGrid />
      </Grid>
    </div>
  );
}

