import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MobileFriendly from "@material-ui/icons/MobileFriendly";
import Assignment from "@material-ui/icons/Assignment";
import ContactSupport from "@material-ui/icons/ContactSupport";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import renderHTML from "react-render-html";
import { injectIntl, intlShape } from "react-intl";
import Fab from "@material-ui/core/Fab";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";


// customising MUI theme
const useStyles = makeStyles(theme => ({
    root:{
        marginTop:"10px"
    },
    tabRoot: {
        display: "grid",
        justifyContent: "center",
        width: "100%"
    },
    heading: {
        color: theme.palette.primary.dark,
        fontSize: "18px"
    },
    fab: {
        marginTop: "-15px",
        position: "absolute",
        right: theme.spacing(2),
        marginLeft:"250px"
    },
    fabRTL:{
        float : "left !important"
    },
    fabLabel: {
        marginBottom: "10px",
        lineHeight: "0.5",
        fontSize: "15px"
    }
}));

//TabContainer functional Component
function TabContainer(props) {
    return <Typography component="div">{props.children}</Typography>;
}

const CampaignKeyAttribute = props => {
    const classes = useStyles();
    const locale = useSelector(state => state.offers.language);
    const [value, setValue] = useState(0);
    var propsLocale = props.campaign.offerDetails[locale];
    

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    var howToRedeem = <div />;
    howToRedeem =
        propsLocale && propsLocale.instructions ? (
            <Typography component="h6" variant="h6">
                {renderHTML(propsLocale.instructions)}
            </Typography>
        ) : (
            ""
        );
    var tnc = <div />;
    tnc =
        propsLocale && propsLocale.termsAndConditions ? (
            <Typography component="h6" variant="h6">
                {renderHTML(propsLocale.termsAndConditions)}
            </Typography>
        ) : (
            ""
        );
    var faqs = <div />;
    faqs =
        propsLocale && propsLocale.faqs ? (
            <Typography component="h6" variant="h6">
                {renderHTML(propsLocale.faqs)}
            </Typography>
        ) : (
            ""
        );

    // const scrollDown = () => {
    //         window.scrollTo(0, document.body.scrollHeight);
    //     };
    const scrollUp = () =>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return (
        <div className={classes.root}>
            {/* how to redeem, t&c and FAQ */}
                <Tabs
                    className={classes.tabRoot}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="secondary"
                    // textColor="secondary"
                >
                    <Tab
                        style={{ display: "block" }}
                        icon={<MobileFriendly />}
                        label={props.intl.formatMessage({
                            id: "steps"
                        })}
                    />
                    <Tab
                        style={{ display: "block" }}
                        icon={<Assignment />}
                        label={props.intl.formatMessage({
                            id: "tc"
                        })}
                    />
                    <Tab
                        style={{ display: "block" }}
                        icon={<ContactSupport />}
                        label={props.intl.formatMessage({
                            id: "faq"
                        })}
                    />
                </Tabs>
                {value === 0 && (
                    <div>
                        <p className={classes.heading}>
                            <b>
                                {props.intl.formatMessage({
                                    id: "how.to.redeem"
                                })}
                            </b>
                        </p>
                        {howToRedeem}
                    </div>
                )}
                {value === 1 && (
                    <TabContainer>
                        <div>
                            <p className={classes.heading}>
                                <b>
                                    {props.intl.formatMessage({
                                        id: "term.condtion"
                                    })}
                                </b>
                            </p>
                            {tnc}
                        </div>
                    </TabContainer>
                )}
                {value === 2 && (
                    <TabContainer>
                        <div>
                            <p className={classes.heading}>
                                <b>
                                    {props.intl.formatMessage({
                                        id: "faq"
                                    })}
                                </b>
                            </p>
                            {faqs}
                        </div>
                    </TabContainer>
                )}

                 <Fab
                        color="primary"
                        aria-label="add"
                        onClick={scrollUp}
                        className={(locale === "fa-FA" || locale === "ar-JO")?classes.fabRTL:classes.fab} 
                    >
                        <div className={classes.fabLabel}>
                        <UpIcon /> {props.intl.formatMessage({
                                        id: "scroll"
                                    })}
                        </div>
                    </Fab>
            <br />
            
        </div>
    );
};

export default injectIntl(CampaignKeyAttribute);
