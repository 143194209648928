import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { getCookie, setUserClickedOkGotIt } from "./cookie";
import { Link } from "react-router-dom";
import { injectIntl, intlShape } from "react-intl";
import { useSelector } from "react-redux";

const styles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  cookieButtonsLTR: {
    float: 'right'
  },
  cookieButtonsRTL: {
    float: 'left'

  },
  cookiePolicyRTL: {
    float: 'right'
  }
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);


function CustomizedDialogs(props) {
  const classes = styles();
  var cookieResponse = getCookie("userClickedOnGotIt");
  const [open, setOpen] = useState(!cookieResponse);
  const locale = useSelector(state => state.offers.language);
  const handleClose = () => {
    setOpen(false);
    setUserClickedOkGotIt();
  };

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} disableBackdropClick={true}>
        <DialogContent dividers onClose={handleClose}>
          <Typography variant="h6" gutterBottom>

            <div className={(locale === "ar-JO" || locale === "fa-FA") ? classes.cookiePolicyRTL :""}>
              {props.intl.formatMessage({ id: "cookieAcceptMessage" })}
            </div>
            <div className={(locale === "ar-JO" || locale === "fa-FA") ? classes.cookieButtonsRTL : classes.cookieButtonsLTR} >
              <Button onClick={handleClose} color="primary" style={{ "margin": "5px" }}>
                {props.intl.formatMessage({ id: "okGotIt" })}
              </Button>
              <Button color="primary" component={Link} to="/learnMore">
                {props.intl.formatMessage({ id: "learnMore" })}
              </Button>
            </div>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}


CustomizedDialogs.propTypes = {
  intl: intlShape.isRequired
};
export default (injectIntl(CustomizedDialogs));
