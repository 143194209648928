/*
 * returnResponse:
 * A plain javascript function for vaidation of fields, which accepts 2 parameter `type` and `value`
 * returns an object with 3 property- formIsValid,error message and
 * name(can be used to set the error message based on which error states can be displayed in the called component)
 */
import { useSelector } from "react-redux";
var XRegExp = require("xregexp");

const regexConfig = {
    phoneNumber: /^[\+]{1}[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/,
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    nameValue: /^[^-\s][\p{L}_\s-]+$/,
    alphaNumeric: /^[^-\s][a-zA-Z0-9_\s-]+$/,
    tellUsMore: /^[^-\s][a-zA-Z0-9_@.,'"$%()<>?+=!*:;/#&+-\s-]+$/,
    country: /^[a-zA-Z]+$/,
    mobileRegex: /^\\d{10}$/,
    pinCodeRegex: /^\d{5,8}$/
};

//returnResponse: will return the object with input type, error message, form vlaid(boolean)
const returnResponse = (result, type, errorMessage) => {
    let res = {};
    res.formIsValid = true;
    if (!result) {
        res.name = type;
        res.errMsg = errorMessage;
        res.formIsValid = false;
    } else res.name = type;
    return res;
};

export const validate = (type, value, regex, digit) => {
    switch (type) {
        case "tellUsMore": {
            const tellUsMore = regexConfig.tellUsMore;
            const result = tellUsMore.test(value);
            return returnResponse(result, type, "tellUsMoreErrMsg");
        }
        case "campaignName":
         {
            const regMobile = new XRegExp("^[^-\\s][\\p{L}0-9_\\s-]+$");
            const result = regMobile.test(value);
            return returnResponse(result, type, `${type}ErrMsg`);
        }
        case "reqesterMobile": case 'submissionID':case "namesOfPassenger": case "vehicleNumber":{
            const regexObj = new XRegExp(regex);
            const result = regexObj.test(value);
            return returnResponse(result, type, `${type}ErrMsg`);
        }

        case "nameValue": {
            const regMobile = new XRegExp("^[\\p{L}_\\s-]+$");
            const result = regMobile.test(value);

            return returnResponse(result, type, "nameErrorMsg");
        }
        case "emailAddress": {
            const regEmail = regexConfig.email;
            const result = regEmail.test(value.toLowerCase());
            return returnResponse(result, type, "emailErrorMsg");
        }
        case "mobileNumber": {
            const regMobile = new XRegExp(regex);
            const result = regMobile.test(value);
            return returnResponse(result, type, "phoneErrorMsg");
        }
        case "country": {
            const country = regexConfig.country;
            const result = country.test(value);
            return returnResponse(result, "countryErr", "countryErrorMsg");
        }
        case "otp": {
            const result = value.length === 4;
            return returnResponse(result, type, "otpError");
        }
        case "coupon": {
            const coupon = new XRegExp(regex);
            const result = value.length === digit && coupon.test(value);
            return returnResponse(result, type, "couponError");
        }
        case "pincode": {
            const pincode = new XRegExp(regex);
            const result = pincode.test(value);
            return returnResponse(result, type, "pincodeError");
        }
        case "passCode": {
            const passCode = new XRegExp(regex);
            const result = passCode.test(value);
            return returnResponse(result, type, "passCodeErrorMsg");
        }
        case "district":
        case "city":
        case "issuingAuthorityArea":
        case "vehicleType":
        case "addressState":
        case "preApprovedGovtList":
        case "idType":
        case "reqesterName":
        case "idNumber":
        case "areaOfAccess":
        case "preReqPeriod":
        case "noOfPassengers":{
            const result = value.length <= 100;
            return returnResponse(result, type, `${type}ErrMsg`);
        }

        case "address":
        case "reasonForPass": {
            const result = value.length <= 250;
            return returnResponse(result, type, `${type}ErrMsg`);
        }
        default:
            return {};
    }
};
