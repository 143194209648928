import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormForEnterprise from "./FormForEnterprise";
import FormForCustomer from "./FormForCustomer";
import { injectIntl, intlShape } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(3)
  },
  group: {
    margin: theme.spacing(1, 0)
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

//
const ContactMainGrid = ({ intl }) => {
  const classes = useStyles();
  const [value, setValue] = useState("customer");

  function handleChangeForRadioButton(event) {
    setValue(event.target.value);
  }

  var common = <FormForCustomer />;
  if (value === "enterprise") {
    common = <FormForEnterprise />;
  }


  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <FormControl component="fieldset" className={classes.formControl}>
            <Typography component="h3" variant="h3">
              {/*<FormattedMessage
                id="contactus.reachout"
                defaultMessage="ReactOut"
              />*/}
              {intl.formatMessage({id: 'contactus.reachout'})}
            </Typography>

            <RadioGroup
              aria-label="userType"
              name="userType"
              className={classes.group}
              value={value}
              onChange={handleChangeForRadioButton}
            >
              <FormControlLabel
                value="customer"
                control={<Radio />}
                label={intl.formatMessage({id: 'contactus.customer'})}
              />
              <FormControlLabel
                value="enterprise"
                control={<Radio />}
                label={intl.formatMessage({id: 'contactus.enterprise'})}
              />
            </RadioGroup>
          </FormControl>
        </div>
        {common}
      </Container>
    </div>
  );
};

ContactMainGrid.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(ContactMainGrid);
