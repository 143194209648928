import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { getCookie } from "../../commons/cookie";
import { useHistory } from "react-router-dom";
import {
    getAppUrlBasedOnOS,
    convertFromHttpToHttps
} from "../../commons/utils";
import {
    userLikedCountPost,
    postForAndroid,
    postForIOS,
    acceptAndContinueCount
} from "../../commons/service";
import RedeemCoupon from "../../modules/redemptions/CommonRedeemScreen";

// customising MUI theme
const useStyles = makeStyles(theme => ({
    actions: {
        display: "block",
        textAlign: "center"
    }
}));

const CampaignAction = props => {
    const classes = useStyles();
    let userAction, targetUrl, title;
    let history = useHistory();
    const [setTermsAndConditions, setsetTermsAndConditions] = useState({
        termsCondition: true
    });
    let [showRedeem, setShowRedeem] = useState(false);
    const urlCode = props.urlCode;
    var appUrl = getAppUrlBasedOnOS(props);
    const locale = useSelector(state => state.offers.language);
    var propsLocale = props.campaign.offerDetails[locale];
    let acceptAndContinue = <div />;
    const config = useSelector(state => state.config);
    let showRedeemInSalesbooster = config.config.showRedeemInSalesbooster
    const resolveFieldValue = (propsLocale, fieldName) => {
        return propsLocale && propsLocale[fieldName]
            ? propsLocale[fieldName]
            : "";
    };

    const redirectUrl = (props, targetUrl) => {
        const code = props.queryString.code;
        const campaignID = props.campaign.campaignId;
        let redirectUrl = targetUrl
            .replace("$CODE", code)
            .replace("$CAMPAIGN", campaignID);
        var visitorId = getCookie("userProfileId");
        var visitorIdObj = { id: parseInt(visitorId) };
        postForAndroid(visitorIdObj, props);
        postForIOS(visitorIdObj, props);
        acceptAndContinueCount(visitorIdObj, props);
        window.location.href = redirectUrl;
    };
    function redirect() {
        let targetUrl = getAppUrlBasedOnOS(props);
        redirectUrl(props, targetUrl);
    }

    if (appUrl !== "") {
        targetUrl = appUrl;
    } else {
        title = resolveFieldValue(propsLocale, "title");
    }
    targetUrl = convertFromHttpToHttps(targetUrl, urlCode);
    if (targetUrl) {
        acceptAndContinue = setTermsAndConditions.termsCondition ? (
            <Button size="large" color="primary" onClick={redirect}>
                {props.intl.formatMessage({ id: "accept.continue" })}
            </Button>
        ) : (
            <Button variant="contained" color="secondary" disabled size="large">
                {" "}
                {props.intl.formatMessage({ id: "accept.continue" })}
            </Button>
        );
    }

    const handleCheckBox = name => event => {
        setsetTermsAndConditions({
            ...setTermsAndConditions,
            [name]: event.target.checked
        });
    };

    const showRedeemPage = () => {
        // commenting temp for zuplier demo
        // setTimeout(() => {
        //     window.scrollBy(0, 1000);
        // }, 200);
        setShowRedeem(true);
    };

    const handleWinnersList = () =>{
        history.push('/winnersList')
    }
    // to display the buttons based on type of campaign
    let redeemCouponButton =  showRedeem ? (
        <RedeemCoupon
            showMedia={false}
            codeDigits = {props.campaign.codeDigits}
            codeFormat = {props.campaign.codeFormat}
            participationServiceUrl={
                props.campaign.participationService
            }
            campaignId={props.campaign.campaignId}
        />
    ) : (
        <Button
            size="large"
            color="primary"
            onClick={showRedeemPage}
        >
            {props.intl.formatMessage({ id: "redeemCoupon" })}
        </Button>
    );

    switch(props.userAction){
        case "appDownload":{
            userAction = (
                <div>
                    {/* t&c check box */}
                    <div style={{ border: "1px solid #f16e00", padding: "8px" }}>
                        <Checkbox
                            label="Terms and Condition"
                            checked={setTermsAndConditions.termsCondition}
                            value="termsCondition"
                            onChange={handleCheckBox("termsCondition")}
                        />
                        <span className={classes.termsConditionWrapper}>
                            {props.intl.formatMessage({ id: "shortTcMessage" })}
                        </span>
                    </div>
                    {/* accept button */}
                    <div className={classes.actions}>{acceptAndContinue}</div>
                </div>
            );
        }break;
        case "watchNwin":{
            userAction = (
                <div className={classes.actions}><Button
                size="large"
                color="primary"
                onClick={handleWinnersList}
            >
                {props.intl.formatMessage({ id: "winnersList" })}
            </Button></div>)
        }break;
        default:{
            userAction = (
                <div className={classes.actions}>
                    {showRedeemInSalesbooster ? 
                    redeemCouponButton : <span />
                    }
                   
                </div>
            );
        }break;
    }

    return <div>{userAction}</div>;
};

export default injectIntl(CampaignAction);