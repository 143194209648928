import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import {Grid, makeStyles} from "@material-ui/core";
import { injectIntl, intlShape } from "react-intl";
import {getCookie} from "../../commons/cookie";
import Share from '@material-ui/icons/ShareOutlined';
import { useState, useEffect } from "react";

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import React from "react"; 

const useStyles = makeStyles(theme => ({
    mtnIcon:{
        color: '#757575'
      },
    paper: {
        width: 300,
        height: 90,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        overflow:"hidden"
    },
    iconColor:{
        color:theme.palette.primary.textSecondary,
        width:"20px"
      },
    button:{
        backgroundColor:"transparent !important",
        transition: 'transform .2s',
        '&:hover': {
          transform: 'scale(1.2)',/* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
        },
        '& span' : {
            color:"black"
          }
    },
    shareCount:{
        marginBottom: "-6px",
        marginLeft:'0px',
        fontSize:'16px'
    }
}));  

const ShareSocial = (props)=> {
    const [shareCount,setShareCount] = useState(props.shareCount);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    useEffect(()=>{
        setShareCount(props.shareCount)
    },[props.shareCount])
    
    function openShareSocialPopUp(event){
        setAnchorEl(event.currentTarget);
    }

    function handleClick(event) {
        props.shareCountFun();
        setShareCount(shareCount + 1);
        var visitorId = getCookie("userProfileId");
        var visitorIdObj =  {"id":parseInt(visitorId)};
        var payLoad = {
            visitor: visitorIdObj,
            campaignOfferId: ""+props.campGeneratedId,
            campaignId:props.campaignId,
            userAction: "SHARE"
        };
        fetch(
            "/offer/portal/interested",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              body: JSON.stringify(payLoad)
            }
          )
          .then(function(response) {
            return response.json();
          })
          .then(function(myJson) {
            console.log(JSON.stringify(myJson));
          })
            .catch(error => {
        });
        
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            
            <Button className={classes.button} size="small" aria-describedby={id} onClick={openShareSocialPopUp}>
                <Share className={classes.iconColor} />
                <span>{props.displayCount ? shareCount:""}</span>
            </Button>
            

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Grid container spacing={2} className={classes.paper} onClick={handleClick}>
                    <Grid item xs={2} sm={2} md={2}>
                        <FacebookShareButton 
                            url={props.shareUrl}
                            quote={props.quote}>
                            <FacebookIcon
                                size={32}
                                round/>
                        </FacebookShareButton>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <TwitterShareButton
                            url={props.shareUrl}
                            title={props.quote}>
                            <TwitterIcon
                                size={32}
                                round/>
                        </TwitterShareButton>
                    </Grid>

                    <Grid item xs={2} sm={2} md={2}>
                        <WhatsappShareButton 
                            url={props.shareUrl}
                            title={props.quote}
                            separator=":: ">
                            <WhatsappIcon size={32} round/>
                        </WhatsappShareButton>

                    </Grid>

                    <Grid item xs={2} sm={2} md={2}>
                        <LinkedinShareButton 
                            url={props.shareUrl}
                            windowWidth={750}
                            windowHeight={600}>
                            <LinkedinIcon
                                size={32}
                                round/>
                        </LinkedinShareButton>
                    </Grid>

                    <Grid item xs={2} sm={2} md={2}>
                        <EmailShareButton 
                            url={props.shareUrl}
                            subject={props.quote}
                            body=" ">
                            <EmailIcon
                                size={32}
                                round/>
                        </EmailShareButton>
                    </Grid>
                </Grid>
            </Popover>
         </React.Fragment>       
    );
}

ShareSocial.propTypes = {
    intl: intlShape.isRequired
};

export default injectIntl(ShareSocial);
