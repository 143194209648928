import React from "react";
import AppRoutes from "./Routes";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import {getUUID,setUserCookie} from "./commons/cookie";
const useStyles = makeStyles(theme => ({
  mainDivWidth: { width: "100%" }
}));

const App = () => {
  var uuid = getUUID();
  setUserCookie(uuid);
  const initState = useSelector(state => state.offers);
  const classes = useStyles();
  const langTranslateObj = window.languageTranslates;
  return (
    <div className={classes.mainDivWidth}>
      <IntlProvider
        locale={initState.language}
        messages={langTranslateObj[initState.language]}
      >
        <AppRoutes />
      </IntlProvider>
    </div>
  );
};

export default App;
