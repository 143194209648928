import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CampaignKeyAttribute from "./CampaignKeyAttributes";
import CampaignAction from "./CampaignAction";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {userLikedCountPost} from "../../commons/service";
import ShareSocial from "../home/ShareSocial";
import AppMenu from "../commons/AppMenu";
import { injectIntl, intlShape } from "react-intl";
import { getCookie } from "../../commons/cookie";
import ThumbUp from "@material-ui/icons/ThumbUpAltOutlined";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import RedeemIcon from '@material-ui/icons/Redeem';
import { useSelector } from "react-redux";
import ReactPlayer from 'react-player'
import PostWatchVideo from '../watch-n-win/PostWatchVideo'
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Footer from '../../commons/Footer'

// customising MUI theme
const useStyles = makeStyles(theme => ({
    bannerImage: {
        backgroundSize: "100% 100%",
        height: "25vh",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            height: "40vh"
        }
    },
    actions: {
        display: "block",
        marginTop: "10px",
        textAlign: "center"
    },
    button: {
        backgroundColor: "transparent !important",
        marginRight: "4px",
        transition: "transform .2s",
        color: `${theme.palette.primary.textSecondary}!important`,
        "&:hover": {
            transform:
                "scale(1.2)" /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
        },
        "& span": {
            color: "#000000"
        }
    },
    thumbsup: {
        color: theme.palette.primary.dark
    },
    root: {
        // padding: "10px",
        position: "relative",
        backgroundColor:"white",
        minHeight:"100vh"
    },
    termsConditionWrapper: {
        fontSize: "12px",
        fontWeight: "600"
    },
    heading:{
        color:theme.palette.primary.main
    },
    showScreen:{
        marginTop:"-30px",
        padding:'5%'
    },
    iconColor:{
        color:theme.palette.primary.textSecondary
      }
}));

function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="static" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" style={{color:"white"}}>{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const roundOfValues = (propsValue, stateValue) =>{
    return (propsValue >9999)?`${(stateValue/10000).toFixed(1)}K`: propsValue;
  }

  const valueNullCheck = (propsLocale, fieldName) => {
    return propsLocale && propsLocale[fieldName]
        ? propsLocale[fieldName]
        : "";
};

// functional component starts here
const OfferDetail = props => {
    const classes = useStyles();
    const locale = useSelector(state => state.offers.language);
    const config = useSelector(state => state.config);
    const surveyQuestions= config.config.surveyQuestions||[];
    const [state, setState] = useState({ mobileNumber: '', totalVideoSec: 0, remainingTiming: 0});
    const [postWatch, setPostWatch] = useState(false);
    const [likeCount, setLike] = useState(props.campaign.likeCount);
    const [shareCount, setShare] = useState(props.campaign.shareCount);
    const [viewCount, setView] = useState(props.campaign.knowMoreCount);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    var propsLocale = props.campaign.offerDetails[locale];
    const videoUrl = props.campaign.videoUrl;

    const calibrateShareCount = () => {
        setShare(shareCount + 1);
    };

    // to post view count to backend
    const increaseViewCount = ()=>{
        var visitorId = getCookie("userProfileId");
        var visitorIdObj = { id: parseInt(visitorId) };
        var payLoad = {
            visitor: visitorIdObj,
            campaignOfferId: "" + props.campaign.id,
            campaignId: props.campaign.campaignId,
            userAction: "KNOW_MORE"
        };
        fetch("/offer/portal/interested", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payLoad)
        }).then(function(response) {
                return response.json();
            })
            .catch(error => {
                console.log(error);
            });
    }

    // to set states(like, share, view) on load
    useEffect(() => {
        let likeValue = roundOfValues(props.campaign.likeCount,likeCount);
        let viewValue = roundOfValues(props.campaign.knowMoreCount,viewCount);
        let shareValue = roundOfValues(props.campaign.shareCount,shareCount);
        setLike(likeValue);
        setView(viewValue);
        setShare(shareValue);
        increaseViewCount(); //increase view count
    }, [props.campaign]);

    useEffect(() => {
        var uidValue = getCookie("uid");
        fetch("/offer/portal/" + uidValue)
            .then(results => {return results.json();})
            .then(data => {setButtonDisabled(data.includes("" + props.campaign.campaignId));});
    }, [isButtonDisabled]);

    useEffect(() => {
    if(state.remainingTiming >=98){
        setState({...state, remainingTiming:100})
    }
    }, [state.remainingTiming]);

    var propsObj = {
        campGeneratedId: props.campaign.id,
        campaignId: props.campaign.campaignId
    };

    const userLikedIt = () => {
        setLike(likeCount+1);
        setButtonDisabled(true);
        userLikedCountPost(propsObj);
    };

    let title = "";
    const offerBannerUrl = valueNullCheck(propsLocale, "offerBannerUrl");
    const offerDescription = valueNullCheck(propsLocale, "offerDescription");
    const shareUrl = props.campaign.shareUrl;
    const campGeneratedId = props.campaign.id;
    const campaignId = props.campaign.campaignId;
    const quote = valueNullCheck(propsLocale, "shareTitle");
    const campaignType = props.campaign.type;
    let showScreen;

    switch (campaignType) { // action, t&c, steps and FAQ's based on campaign type starts here
        case 'watch-n-win': {
            showScreen = <div>
            <CampaignAction campaign={props.campaign} userAction={"watchNwin"}queryString={props.queryString}/>
                <CampaignKeyAttribute campaign={props.campaign} /></div>
        }
            break;
        case 'app_download': {
            showScreen = <div>
                <CampaignKeyAttribute campaign={props.campaign} />
                <CampaignAction campaign={props.campaign} urlCode={props.urlCode}userAction={"appDownload"}queryString={props.queryString}/>
            </div>;
            break;
        }
        case 'sales_booster': {
            showScreen = <div>
                <CampaignAction campaign={props.campaign} urlCode={props.urlCode}userAction={"redeemCoupon"}queryString={props.queryString}/>
                <CampaignKeyAttribute campaign={props.campaign} />
            </div>;
            break;
        }
        default: {showScreen = <div><CampaignKeyAttribute campaign={props.campaign} /></div>
            break;}
    }// action, t&c, steps and FAQ's based on campaign type ends here

    // video handlers starts here
    const onEndHandler = () => {
        setPostWatch(true);
    }
    const handleDuration = (res) => {
        setState({ ...state, ["totalVideoSec"]: res });
    }
    const handleOnProgress = (res) => {
        let remainingTime = res.playedSeconds === 0?state.remainingTiming: Math.ceil((res.playedSeconds/state.totalVideoSec)*100);
        setState({ ...state, ["remainingTiming"]: remainingTime });
    }
    // video handlers ends here

    // get hero section data based on campaign type starts here
    let heroSectionVideo = <div>
        <br />
        <div style={{ marginTop: "38px" }}>
            {/* <p style={{ float: "right",marginBottom:"-2px" }}> {state.remainingTiming.toFixed(2)}/{state.totalVideoSec.toFixed(2)} Sec</p> */}
            <ReactPlayer
                url={videoUrl}
                playing={true}
                onEnded={onEndHandler}
                style={{ margin: "0 auto" }}
                width={"100%"}
                onDuration={handleDuration}
                onProgress={handleOnProgress}
            />
            <div style={{ marginTop: "-46px" }}><CircularProgressWithLabel value={state.remainingTiming}/></div>
        </div>
        {postWatch ? <PostWatchVideo surveyQuestions={surveyQuestions} /> : <span />}
    </div>

    // let heroSectionImage = <div
    //     className={classes.bannerImage}
    //     style={{
    //         marginTop: config.config.hideNaveBar ? '0px' : '70px',
    //     backgroundImage: `url(${offerBannerUrl})` }}
    //     />
    let heroSectionImage = <img src={offerBannerUrl} alt="Banner Image" style={{width:"100%",height:"auto",marginTop:"58px"}}></img>
        // get hero section data based on campaign type ends here

    return (
        <div>
        <div className={classes.root}>
            {config.config.hideNaveBar ? '': <AppMenu />}
            <div>
                {campaignType === "watch-n-win"? <div> {heroSectionVideo}</div>:<div> {heroSectionImage}</div>} {/* hero section*/}
                <Typography variant="h4" className={classes.heading}> {/* offer description */}
                    {title}
                </Typography>
                {/* like, share and view buttons */}
                <div className={classes.actions}>
                    {isButtonDisabled ?
                     (<Button disabled={true}size="small"className={classes.button}>
                            <ThumbUp className={classes.thumbsup}/> {likeCount}
                        </Button>) :
                        <Button size="small" onClick={userLikedIt} className={classes.button}>
                            <ThumbUp className={classes.iconColor}/> {likeCount}
                        </Button>
                    }
                    <ShareSocial
                        shareCountFun={calibrateShareCount}
                        displayCount={true}
                        shareCount={shareCount}
                        campGeneratedId={campGeneratedId}
                        campaignId={campaignId}
                        shareUrl={shareUrl}
                        quote={quote}
                    />
                    <Button size="small" className={classes.button}>
                        <Visibility className={classes.iconColor}/>{viewCount}
                    </Button>
                    <br />
                    <Typography variant="h4" color="textSecondary" component="h4">
                        {offerDescription}
                    </Typography>
                    <br />
                </div>
                <div className={classes.showScreen}>
                {showScreen}
                </div>
            </div>
        </div>
        {Object.keys(props.campaign.footers).length>0?<Footer footerValue={props.campaign.footers}/>:<span/>}
        </div>
    );
};

OfferDetail.propTypes = {
    intl: intlShape.isRequired
};

export default injectIntl(OfferDetail);