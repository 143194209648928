import {makeStyles} from "@material-ui/core";
import React from "react";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import AppMenu from "../commons/AppMenu";

const useStyles = makeStyles(theme => ({
    card: {
        flexGrow: 1,
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        maxWidth: 700
    },
    media: {
        height: 300,
        alignItems: 'center',
        backgroundSize: 'contain'
    }
}));

export default function PageNotFound() {

    const classes = useStyles();

    function goToHome() {
       window.location.href="/";
    }

    return (
        <div>
            <AppMenu/>
            <Grid container justify="center" onClick={goToHome}>
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.media}
                        image="/404.png" />
                </Card>

            </Grid>
        </div>
    );
}
