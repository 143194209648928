import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import Grid from "@material-ui/core/Grid";
import { injectIntl } from "react-intl";

const backgroundImage =
  "https://sharingkali.com/wp-content/uploads/2020/06/Kado-ulang-tahun.jpg";

const styles = theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: "#7fc7d9", // Average color of the background image.
    backgroundPosition: "center"
  },
  button: {
    minWidth: 200
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10)
    }
  },
  more: {
    marginTop: theme.spacing(2)
  },
  root: {
    marginBottom:'20px'
  },
  content: {
    display:'block',
    paddingTop:theme.spacing(5)
  }
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
    <Grid container className={classes.content}>
        <ProductHeroLayout backgroundClassName={classes.background}>
          <div style={{"textAlign":"center", "marginTop": "100px","marginBottom":"100px"}} >
          <Typography
            color="inherit"
            align="center"
            variant="h2"
            marked="center"
          >
            {props.intl.formatMessage({ id: "heroImageTitle" })}
            
          </Typography>
          </div>
        </ProductHeroLayout>
      </Grid>
    </div>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(injectIntl(ProductHero));
