import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { injectIntl } from "react-intl";
import { useState } from "react";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={event => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    fontForHeader:{ fontSize: '16.0pt', lineHeight: '107%' },
    fontForPar:{ fontSize: '14.0pt', lineHeight: '107%' },
    fontForSpan:{ fontSize: '12.0pt', lineHeight: '107%' },
    linkColor:{ color: '#2e74b5' }
}));

const NavTabs = ({ intl }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const origin = window.location.origin;
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                >
                    <LinkTab label={intl.formatMessage({ id: 'privacy.policy' })} href="/drafts" {...a11yProps(0)} />
                    <LinkTab label={intl.formatMessage({ id: 'cookie.policy' })} href="/trash" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <div> 
                    <p><strong><span className={classes.fontForHeader}>{intl.formatMessage({ id: 'privacy.policy' })}</span></strong></p>
                    <p>{intl.formatMessage({ id: 'privacy.policy.para1' })}
                        <a href={origin}>
                            <span className={classes.linkColor}>{origin}</span>
                        </a>{intl.formatMessage({ id: 'privacy.policy.para2' })}
                    </p>
                    {/* Commented because of pepsi test case */}
                    {/* <p>{intl.formatMessage({ id: 'privacy.policy.para3' })}
                        <a href={origin}>{origin}/formContacts</a>.
                    </p> */}
                    <p><strong>
                        <span className={classes.fontForSpan}>{intl.formatMessage({ id: 'logFiles' })}</span>
                    </strong>
                    </p>
                    <p>{intl.formatMessage({ id: 'privacy.policy.value1' })}</p>
                    <p><strong><span className={classes.fontForSpan}>{intl.formatMessage({ id: 'pp.cookie' })}</span></strong></p>
                    <p>{intl.formatMessage({ id: 'pp.cookie.subheader1' })}</p>
                    <p>{intl.formatMessage({ id: 'pp.cookie.subheader2' })}</p>
                    <p><strong><span className={classes.fontForPar}>{intl.formatMessage({ id: 'privacy.policies' })}</span></strong></p>
                    <p><strong><span className={classes.fontForSpan}>{intl.formatMessage({ id: 'children.information' })}</span></strong></p>
                    <p>{intl.formatMessage({ id: 'children.information1' })}</p>
                    <p>{intl.formatMessage({ id: 'pp.message1' })}</p>
                    <p><strong><span className={classes.fontForSpan}>{intl.formatMessage({ id: 'pp.online.privacy' })}</span></strong></p>
                    <p>{intl.formatMessage({ id: 'pp.online.message1' })}</p>
                    <p><strong><span className={classes.fontForSpan}>{intl.formatMessage({ id: 'consent' })}</span></strong></p>
                    <p>{intl.formatMessage({ id: 't.and.c' })}</p>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div>
                    <p><strong><span className={classes.fontForHeader}>{intl.formatMessage({ id: "cookie.policy" })}</span></strong></p>
                    <p>{intl.formatMessage({ id: "cookie.policy.accesbile" })} <a href={origin}><span className={classes.linkColor}>{origin}</span></a>.</p>
                    <p><strong><span className={classes.fontForSpan}>{intl.formatMessage({ id: "what.are.cookie" })}</span></strong></p>
                    <p>{intl.formatMessage({ id: "cookie.description" })}</p>
                    <p>{intl.formatMessage({ id: "cookie.wiki" })}</p>
                    <p>&nbsp;</p>
                    <p><strong><span className={classes.fontForSpan}>{intl.formatMessage({ id: "How We Use Cookies" })}</span></strong></p>
                    <p>{intl.formatMessage({ id: "cookie.desc.reason" })}</p>
                    <p><strong><span className={classes.fontForSpan}>{intl.formatMessage({ id: "disabling.cookie" })}</span></strong></p>
                    <p>{intl.formatMessage({ id: "cookie.desc.1" })}</p>
                    <p><strong><span className={classes.fontForSpan}>&nbsp;</span></strong></p>
                    <p><strong><span className={classes.fontForSpan}>{intl.formatMessage({ id: "cookie.we.set" })}</span></strong></p>
                    <p><strong>{intl.formatMessage({ id: "form.related.data1" })} <br /></strong>{intl.formatMessage({ id: "form.related.data2" })}</p>
                    <p><strong>{intl.formatMessage({ id: "site.preference.cookies" })}<br /></strong>{intl.formatMessage({ id: "site.preference.cookies.data1" })}</p>
                    <p><strong><span className={classes.fontForSpan}>&nbsp;</span></strong></p>
                    <p><strong><span className={classes.fontForSpan}>{intl.formatMessage({ id: "third.party.cookie" })}</span></strong></p>
                    <p>{intl.formatMessage({ id: "third.party.special" })}</p>
                    <p>{intl.formatMessage({ id: "site.use.google.analtics" })}</p>
                    {/* Commented for pepsi test case */}
                    {/* <p><strong>
                        <span className={classes.fontForSpan}>
                            {intl.formatMessage({ id: "more.info" })}<br />
                        </span>
                    </strong>
                        {intl.formatMessage({ id: "still.more.info" })}
                        <a href="{origin}/formContacts">
                        <span className={classes.linkColor}>{origin}/formContacts</span></a>.</p> */}
                </div>
            </TabPanel>
        </div>
    );
}

export default injectIntl(NavTabs);