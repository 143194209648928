import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { injectIntl } from "react-intl";

const DialogCommon = ({ handleClose,propsVal, openVal, intl }) => {
  return (
    <Dialog
      open={openVal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.formatMessage({ id: 'popup.query.submit.success' })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {intl.formatMessage({ id: 'popup.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );

}

export default injectIntl(DialogCommon);