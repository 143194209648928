import React from "react";
import ConsentView from '../../commons/ConsentView'
import { Redirect } from 'react-router-dom';
import { campaignInfo } from '../../actions/offers'
import { connect } from "react-redux";
import {postForVisitorCount} from '../../commons/service';
import CustomizedDialogs from '../../commons/intromodal';
const queryString = require('query-string');
class CampaignHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign: { "campaign": {} },
            codeInfo: "",
            urlCode: "",
            error: false,
            parsedQueryString:"",
            isfetching:false
        };
    }

    componentDidMount() {
        const parsedQueryString = queryString.parse(this.props.location.search);
        const shortCode = parsedQueryString.code;
        const msisdn = parsedQueryString.user;
        this.setState({parsedQueryString:parsedQueryString});
        let code = window.location.pathname.replace("/", "");
        let campaignId;
        if (code.includes('-')) {
            campaignId = code;
        } else {
           this.setState({ urlCode :code})
        }
       
        if (campaignId) {
            fetch('/discover/' + campaignId)
                .then(results => {
                    return results.json();
                }).then((data) => {
                    this.props.getCampaignInfo(data);
                    this.setState({isfetching:true})
                });
        } else {
            fetch('/details/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code })
            }).then((data) => {
                if (data.status === 400) {
                    this.setState({ error: true });
                    return true;
                }
                return data.json();
            }).then(codeInfo => {
                fetch('/discover/' + codeInfo.campaignId)
                    .then(campaign => {
                        return campaign.json();
                    }).then((campaignData) => {
                        this.setState({ codeInfo: codeInfo, campaign: campaignData })
                    });

            }).catch((error) => {
                console.log(error);
                this.setState({ error: true });

            });
            this.setState({ urlCode: code });
        }
        var src =  this.props.location.search;
        postForVisitorCount(src,campaignId,shortCode,msisdn);
    }

    render() {

        if (this.state.error) {
            return <Redirect to="/notfound" />
        }
        var loadOfferDetails = true;
        if (this.props.offers.campaignInfo.campaignId === undefined) {
            loadOfferDetails = false;
        }
        return (
            <React.Fragment>
            <CustomizedDialogs/>
                {
                    loadOfferDetails && this.state.isfetching ?
                    <ConsentView campaign={this.props.offers.campaignInfo} urlCode={this.state.urlCode} queryString={this.state.parsedQueryString}/> :
                     <div></div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state =>{
    return { offers: state.offers};
};

const mapDispatchToProps = dispatch => ({
    getCampaignInfo: (campaignData) => {
        dispatch(campaignInfo(campaignData));
    }
});

export default connect(mapStateToProps,mapDispatchToProps)(CampaignHome);
