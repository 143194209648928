import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, createMuiTheme, useTheme } from "@material-ui/core/styles";
import TranslateIcon from "@material-ui/icons/Translate";
import { useSelector, useDispatch } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {getDirection} from '../../../src/commons/utils'
import { updateLanguage } from "../../actions/language";

const useStyles = makeStyles((theme) => ({
  translation: {
    padding:"10px",
    "& .MuiIconButton-root": {
      color: "white",
    },
  },
  }));


const ChangeLanguage = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [language, setLanguage] = useState(
        useSelector((state) => state.offers.language)
      );
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    let direction;

    function handleLanguageSelect(selectedLanguage) {
        localStorage.setItem("lang", selectedLanguage);
        dispatch(updateLanguage(selectedLanguage));
        setLanguage(selectedLanguage);
        setAnchorEl(null);
      }

    return(
        <div>
            {theme.languages.length > 1 ? (
          <div className={classes.translation}>
            <IconButton
              aria-label="upload picture"
              component="span"
              onClick={handleClick}
            >
              <TranslateIcon />
            </IconButton>
            <p style={{ color: "white", marginTop: "-9px" }}>
              {language === "ar-JO" ? "عربى" : "English"}
            </p>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {theme.languages.map((themeLanguage, index) => (
                <MenuItem
                  onClick={handleLanguageSelect.bind(
                    this,
                    themeLanguage.local,
                    themeLanguage.lang
                  )}
                >
                  {themeLanguage.lang}
                </MenuItem>
              ))}
            </Menu>
          </div>
        ) : (
          ""
        )}
        </div>
    )
}

export default injectIntl(ChangeLanguage);