import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { injectIntl } from "react-intl";
import Container from "@material-ui/core/Container";
import { validate } from '../../commons/validation';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSelector } from "react-redux";
import ReCaptcha from "../commons/captcha";
import ChangeLanguage from '../commons/languageChange'
import { getDirection } from '../../commons/utils'
import InfoIcon from '@material-ui/icons/Info';
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Loader from '../commons/Loader';
import ApiErrorHandler from '../../commons/apiErrorHandler'

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  arabicBackground: {
    backgroundImage: `url(${theme.redeemBackground_ar})`,
    height: "120vh", backgroundSize: "cover",
    direction: "rtl",

    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${theme.redeemMobileBackground_ar})`
    }
  },
  englishBackground: {
    backgroundImage: `url(${theme.redeemBackground_en})`,
    height: "120vh", backgroundSize: "cover",
    direction: "ltr",

    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${theme.redeemMobileBackground_en})`
    }
  },
  inputContainer_ar: {
    marginRight: "100px",
    [theme.breakpoints.down("sm")]: {
      marginRight: '0px'
    }
  },
  inputContainer_en: {
    marginLeft: "100px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: '0px'
    }
  },
  orangeHeading: {
    color: theme.redeemHeadingPrimaryColor?theme.redeemHeadingPrimaryColor:'#f16e00',
    fontSize: "30px",
    fontWeight: 700
  },
  exchangeVoucher: {
    color: theme.redeemSecondaryColor? theme.redeemSecondaryColor:'white',
    fontSize: "25px",
    fontWeight: 700,
    marginTop: '50px'
  },
  margin: {
    margin: theme.spacing(1),
  },
  logo: {
    textAlign: "center",
    marginTop: "45px",
    padding:"10px"
  },
  // textField: {
  //   marginLeft: theme.spacing(1),
  //   marginRight: theme.spacing(1)
  // },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  },
  card: {
    marginTop: "20px",
    maxWidth: 400,
    direction: "ltr",
    opacity: "0.8",
    transition: "transform .2s",
    "&:hover": {
      transform:
        "scale(1.1)"
    }
  },
  textField: {
    background: "white",
    borderRadius: "10px",
    width: "302px"
  },
  submit: {
    marginTop: "15px",
    width: "300px",
    label: {
      color: "#ffffff"
    }
  },
  legend: {
    textAlign: "right"
  },
  errorMessage: {
    color: 'red',
    marginLeft: '8px'
  },
  errorMessageMain: {
    color: 'white',
    fontSize: '20px'
  }
}));

const rtlTheme = createMuiTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
});

const getValidValue = (expression, resultValue1, resultValue2)=>{
  return expression? resultValue1:resultValue2;
}

function handleAllInputFields(setState, state, mobileRegex, mobileDigit, couponRegex, couponDigit, setError, error, props) {
  return (event, input) => {
    const value = event.target.value;
    let inputValue, regex, digit;
    setState({ ...state, [input]: event.target.value });
    switch (input) {
      case "mobileNumber": {
        inputValue = value;
        regex = mobileRegex;
        digit = mobileDigit;
        break;
      }
      case "coupon": {
        inputValue = value;
        regex = couponRegex;
        digit = couponDigit;
        break;
      }
      default: break;
    }

    const res = validate(input, inputValue, regex, couponDigit);
    const name = res.name;

    if (!res.formIsValid) {

      setError({ ...error, [name]: props.intl.formatMessage({ id: res.errMsg }, { digit: digit }), ['errStatus']: true });
    }
    else {
      setError({ ...error, [name]: '', ['errStatus']: false });
    }

  };
}

const RedeemCoupon = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const config = useSelector(state => state.config);
  const locale = useSelector(state => state.offers.language);
  const countryCode = config.config.countryCodeOrangeMoney;
  const mobileDigit = config.config.mobileDigitForOrangeMoney;
  const mobileRegex = config.config.mobileRegexForOrangeMoney;
  const couponRegex = config.config.couponRegexForOrangeMoney;
  const couponDigit = config.config.couponDigitForOrangeMoney;
  const supplierId = config.config.supplierId;
  const couponRedeemUrl = config.config.couponRedeemUrl;
  const [error, setError] = useState({ coupon: '', mobileNumber: '', errStatus: false });
  const [state, setState] = useState({ coupon: "", mobileNumber: '' });
  const [response, setResponse] = useState('');
  const [showRedeem, setShowRedeem] = useState(false);
  const [showLoader,setShowLoader] = useState(false);

  // Handle input fields
  const handleInput = handleAllInputFields(setState, state, mobileRegex, mobileDigit, couponRegex, couponDigit, setError, error, props);

  const showApiResponse = (message) =>{
    //setResponse(message);
    // Temporary Fix: Revert after DI-5512 is closed.
    setResponse(props.intl.formatMessage({ id: message}));

    setState({ coupon: "", mobileNumber: "" });
  }
  // send coupon redeem details
  const handleSubmitForRedemption = event => {
    if (!error.errStatus && (state.mobileNumber.length > countryCode.length && state.coupon.length !== 0)) {
      setShowLoader(true);
      const countryCodeNoPlus = countryCode.split('+').join('');
      let phoneNumber = (countryCodeNoPlus + state.mobileNumber);
      let url = props.participationServiceUrl ? props.participationServiceUrl : couponRedeemUrl;
      const payLoad = {
        code: state.coupon,
        campaignId: props.campaignId ? props.campaignId : '',
        sender: phoneNumber,
        channelType: "PORTAL",
        supplierId: supplierId,
        responseAsMessage: false

      };
      // holds good for only nginx configuration
      fetch(
        url,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payLoad)
        }
      )
        .then(function (result) {
          setShowLoader(false);
          let apiResponse= ApiErrorHandler(result);
          showApiResponse(apiResponse)
        })
        .catch((e) => {
          setShowLoader(false);
          setResponse(props.intl.formatMessage({ id: "somethingWrong" }));
          console.log(`%cerror in redeeming code>>>>>>>>>>>>>>>>>>>${e}`,'color: white; background-color: red; font-size: 20px; padding: 3px;',);
        })
    }
    else {
      setResponse(props.intl.formatMessage({ id: "fillDetail" }));
    }
    event.preventDefault();
  };
  const onCaptchaLoad = () => {
    console.log("Captcha loaded!!!!!!!");
  };

  const updateShowRedeem = () => {
    setShowRedeem(true);

  };
  const expiredCallback = () => {
    setShowRedeem(false);
  }
  return (
    <div
      className={getValidValue(( locale === 'ar-JO'),classes.arabicBackground,classes.englishBackground)}>
      <ChangeLanguage />
      <Container component="main" maxWidth="xs"
        className={getValidValue((locale === 'ar-JO'),classes.inputContainer_ar,classes.inputContainer_en)}>

        <Typography gutterBottom variant="h4" component="h4" className={classes.orangeHeading}>
          {props.intl.formatMessage({ id: "orangeMoneyTitle" })}
        </Typography>
        <Typography gutterBottom variant="h4" component="h4" className={classes.exchangeVoucher}>
          {props.intl.formatMessage({ id: "exchangeVoucher" })}
        </Typography>
        <br /><br />
        <form className={classes.form} onSubmit={handleSubmitForRedemption}>
          <ThemeProvider theme={rtlTheme}>

            <FormLabel className={classes.errorMessageMain}>
              {props.intl.formatMessage({ id: "couponCode" })}
            </FormLabel>
            <Grid container>
              <Grid item>
                <TextField
                  className={classes.textField}
                  placeholder={props.intl.formatMessage({ id: "couponCode" })}
                  value={state.coupon}
                  onChange={e => handleInput(e, "coupon")}
                  fullWidth
                  variant="filled"
                  type={getValidValue((props.codeFormat === "NUMERIC" ),"number","")}
                />
                <p className={classes.errorMessage}>{error.coupon}</p>
              </Grid>
            </Grid>
            <FormLabel className={classes.errorMessageMain}>
              {props.intl.formatMessage({ id: "contactus.form.customer.mobile" })}
            </FormLabel>
            <Grid container>
              <Grid item>
                <TextField
                  dir="ltr"
                  className={classes.textField}
                  fullWidth
                  placeholder={`77XXXXXXX`}
                  type="number"
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {countryCode}
                      </InputAdornment>
                    ),
                  }}
                  value={state.mobileNumber}
                  onChange={e => handleInput(e, "mobileNumber")}
                />
                <p className={classes.errorMessage}>{error.mobileNumber}</p>

              </Grid>
            </Grid>

          </ThemeProvider>
          {/* captcha */}
          <ReCaptcha
            onloadCallback={onCaptchaLoad}
            verifyCallback={updateShowRedeem}
            expiredCallback={expiredCallback}
            lang={getValidValue((locale === 'ar-JO'),'ar','en')}
          />
          {/* redeem button */}
          {showRedeem === true ? <Button
            type="submit"

            variant="contained"
            color="secondary"

            className={classes.submit}
          >
            {props.intl.formatMessage({ id: "redeem" })}
          </Button> : <span />}
          {/* error message */}
          {getValidValue(response,(<p className={classes.errorMessageMain}><InfoIcon /> &nbsp;{response}</p>),<span/>)}
        </form>
        <div style={{display:"flex"}}>
        <div className={classes.logo}
          style={{ float: getValidValue((locale === 'ar-JO'),'right','left')}}>
          <img src={theme.redeemPagePrimaryLogo} alt="Logo" />
        </div>
        <div className={classes.logo}
          style={{ float: getValidValue((locale === 'ar-JO'),'right','left')}}>
          <img src={theme.redeemPageSecondaryLogo} alt="Logo" />
        </div>
        </div>
      </Container>
      {showLoader ? <Loader/>: <span />}
    </div>
  );
};
export default injectIntl(RedeemCoupon);