import React, { useState,useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from "@material-ui/styles";
import { grey, } from "@material-ui/core/colors";
import InputLabel from "@material-ui/core/InputLabel";
import { injectIntl } from "react-intl";
import Typography from '@material-ui/core/Typography';
import { validate } from './validation'
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector } from "react-redux";
import { getCookie} from "./cookie";


function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        //   border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    optionContainer: {
        display: "flex",
        flexDirection: "column"
    },
    questionContainer: {
        padding: "5px"
    },
    optionButtons: {
        margin: "5px",
        textTransform: "none"
    },
    errorMessage: {
        color: 'red'
    }
}));
function Survey(props) {

    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const config = useSelector(state => state.config);
    const jupilerHeader = config.config.jupilerHeader;
    const [open, setOpen] = React.useState(true);
    const language = useSelector(state => state.offers.language);
    const surveyQuestions = props.surveyQuestions[language];
    const [state, setState] = useState({ nameValue: '', questionToShow: 0, askNumber: false, emailAddress: '',surveyDetail:'',termsCondition: false,futurePromotion:false});
    const [error, setError] = useState({ emailAddress: '', nameValue: '' });
    let surveyAnswers='';



    const handleInput = (event, inputName) => {
        let value = event.target.value;
        setState({ ...state, [inputName]: value });
        const res = validate(inputName, value);
        const name = res.name;
        if (!res.formIsValid) {
            setError({ ...error, [name]: props.intl.formatMessage({ id: res.errMsg }), ['errStatus']: true });
        }
        else {
            setError({ ...error, [name]: '', ['errStatus']: false });
        }
    }
    const handleCheckBox = name => event => {
        setState({
            ...state,
            [name]: event.target.checked
        });
    };

    const handleClose = () => {
        setOpen(false);
    };
    const customButton = createMuiTheme({
        palette: {
            primary: {
                light: grey[800],
                main: grey[800],
                dark: grey[800],
                backgroundColor: grey[800],
            }
        },
        typography: {
            button: {
                textTransform: "none",
                color: "black"
            }
        }
    });

    let handleSurvey = (qId,option) => {
        surveyAnswers = state.surveyDetail+`${qId}:${option}|`;

        setState({ ...state, questionToShow: state.questionToShow + 1 ,surveyDetail:surveyAnswers});

        if (surveyQuestions.length === state.questionToShow + 1) {
            setState({ ...state, askNumber: true,surveyDetail:surveyAnswers})
        }
    }
    const handleContactInfo = (event) => {
        setOpen(false);
        // zuplier CDP post method starts here
        let locationData = window.location;
        let cdoUrl  ="https://in.treasuredata.com/postback/v3/event/eur_source/bel_web_form"
        var tagCookie = getCookie("_td");
        let payLoad = {
            abi_brand : "Jupiler",
            abi_campaign : "win.Jupiler.be",
            abi_form: "CampaignForm",
            td_unify: true,
            td_import_method: "postback-api-1.2",
            td_client_id: tagCookie,
            td_url: locationData.href,
            td_host: locationData.host
          }
        fetch(
            cdoUrl,
            {
              method: "POST",
              headers: jupilerHeader,
              body: JSON.stringify(payLoad)
            }
          )
          .then(function (result) {
            console.log("<<<<<------------pushed gtag data----------->>>>>>")
          })
          .catch((e) => {
            console.log("<<<<<------------error while pushing gtag----------->>>>>>",e)
          })
        // zuplier CDP post method ends here
        props.disableSurvey(state);
        event.preventDefault();
    }

    let askNumber = <div >
        <form onSubmit={handleContactInfo} className={classes.form}>

            <Typography gutterBottom variant="h4" component="h4">
                {props.intl.formatMessage({ id: "thankYouParticipation" })}
            </Typography>
            <br />
            <p id="district-required-label">
                {props.intl.formatMessage({ id: "askName" })}
            </p>
            <TextField
                id="nameValue"
                name="nameValue"
                variant="outlined"
                value={state.mobileNumber}
                onChange={(e) => handleInput(e, "nameValue")}
                label={props.intl.formatMessage({ id: "contactus.form.customer.name" })}
                required
                fullWidth
            />
            <span className={classes.errorMessage}>{error.nameValue}</span>
            <br /><br />
            <p id="district-required-label">
                {props.intl.formatMessage({ id: "askEmail" })}
            </p>
            <TextField
                id="emailAddress"
                name="emailAddress"
                variant="outlined"
                value={state.referrerMobileNumber}
                onChange={(e) => handleInput(e, "emailAddress")}
                label={props.intl.formatMessage({ id: "contactus.form.customer.email" })}
                fullWidth
                required
            />
            <span className={classes.errorMessage}>{error.emailAddress}</span>

            <br /> <br />
            <Checkbox
                label="Terms and Condition"
                checked={state.termsCondition}
                value="termsCondition"
                onChange={handleCheckBox("termsCondition")}
            /><span className={classes.termsConditionWrapper}>
            {props.intl.formatMessage({ id: "tcAccepted" })}
        </span><br/>
            <Checkbox
                label="future promotion"
                checked={state.futurePromotion}
                value="future promotion"
                onChange={handleCheckBox("futurePromotion")}
            />
            <span className={classes.termsConditionWrapper}>
                            {props.intl.formatMessage({ id: "acceptFuturePromotion" })}
                        </span><br/>
            <Button variant="contained" color="primary" type="submit"  disabled={!state.termsCondition}>
                {props.intl.formatMessage({ id: "contactus.form.customer.submit" })}
            </Button>
        </form>
    </div>

    let question =
        < div className={classes.questionContainer} >
            <Typography gutterBottom variant="h4" component="h4">
                {props.intl.formatMessage({ id: "surveyTitle" })}
            </Typography>
            <br />
            <InputLabel id="question">
                {surveyQuestions[state.questionToShow].question}
            </InputLabel>
            <br />
            <div className={classes.optionContainer}>
                <ThemeProvider theme={customButton}>
                    <Button variant="outlined" color="primary" className={classes.optionButtons}
                        onClick={(e) => handleSurvey(surveyQuestions[state.questionToShow].qId,surveyQuestions[state.questionToShow].option1)}>
                            {surveyQuestions[state.questionToShow].option1}</Button>
                    <Button variant="outlined" color="primary" className={classes.optionButtons}
                        onClick={(e) => handleSurvey(surveyQuestions[state.questionToShow].qId,surveyQuestions[state.questionToShow].option2)}>
                            {surveyQuestions[state.questionToShow].option2}</Button>
                    <Button variant="outlined" color="primary" className={classes.optionButtons}
                        onClick={(e) => handleSurvey(surveyQuestions[state.questionToShow].qId,surveyQuestions[state.questionToShow].option3)}>
                            {surveyQuestions[state.questionToShow].option3}</Button>
                </ThemeProvider>
            </div>
        </div >


    const body = (
        <div style={modalStyle} className={classes.paper}>
            {state.askNumber && surveyQuestions.length !== state.questionToShow ? <div>{askNumber}</div> : <div>{question}</div>}
        </div>
    );
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}
export default injectIntl(Survey);