import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";
import fa from "react-intl/locale-data/fa";
import hi from "react-intl/locale-data/hi";
import ar from "react-intl/locale-data/ar";
import ne from "react-intl/locale-data/ne";
import nl from "react-intl/locale-data/nl";

export const registerLocale = () =>{
    addLocaleData(en);
    addLocaleData(fr);
    addLocaleData(fa);
    addLocaleData(hi);
    addLocaleData(ar);
    addLocaleData(ne);
    addLocaleData(nl);
}