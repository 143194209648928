export function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
    return false;
  }

export function setUserCookie(uuidNo){
  let d = new Date();
  d.setTime(d.getTime() + (365 * 24 *60 * 60 *1000));
  let uid = getCookie("uid");
  if(!uid){
    let expires = new Date();  
    expires.setTime(expires.getTime() + 31536000000); //1 year  
    document.cookie = "uid" + '=' + uuidNo + ';expires=' + expires.toUTCString();
  }
}

export function setUserVisitorProfile(userVisitProfile){
  document.cookie = "userProfileId="+userVisitProfile.id;
}

export function setUserClickedOkGotIt(){
  document.cookie = "userClickedOnGotIt="+true;
}

export function getUUID(){
  var d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
      d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}
