export function updatelist(offers){
    return {
        type: "UPDATE_LIST",
        payload: offers
    }
}

export function campaignInfo(campaigns){
    return {
        type:"CAMPAIGN_INFO",
        payload: campaigns
    }
}

export function searchResult(task){
    return {
        type: "SEARCH_RESULT",
        payload: task
    }
}

export function sortCampaign(data){
    return{
        type: "SORT_CAMPAIGN",
        payload:data
     }
}

export function updateLikeCount(data){
    return{
        type: "UPDATE_LIKE_COUNT",
        payload:data
     }
}

export function updateConfig(config){
    return{
        type: "UPDATE_CONFIG",
        payload:config
     }
}