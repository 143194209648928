import React from "react";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    loader: {
        width: "100%",
        height: "100vh",
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: "rgba(255, 255, 255, 0.95)",
        zIndex: 1010
    }
}))

const Loader = () => {
    const classes = useStyles();
    return (
        <div className={classes.loader}>
            <img src={"/loader.gif"} alt="loader" />
        </div>
    )
}

export default Loader;