import React,{useState,useEffect} from "react";
import Recaptcha from 'react-recaptcha';
import { useSelector } from "react-redux";


const ReCaptcha = (props) => {

    const captchaSiteKey = useSelector(state => state.config.config.captchaSiteKey);
    const local = useSelector(state => state.offers.language).split('-')[0]||''; // extracting the language
    const [captchaLang,setCaptchaLang] = useState(props.lang?props.lang:local);
    useEffect(() => {
        setCaptchaLang(props.lang);
    }, [props.lang]);

    return (
        <div>
            {captchaSiteKey && (props.lang === captchaLang) &&
                <Recaptcha
                    sitekey={captchaSiteKey}
                    render="explicit"
                    verifyCallback={props.verifyCallback}
                    onloadCallback={props.onloadCallback}
                    expiredCallback={props.expiredCallback}
                    hl={captchaLang} // passing the props hl as ar changes the language to Arabic
                />
            }
        </div>
    );
};
export default ReCaptcha;
