import React from "react";
import AppMenu from "../modules/commons/AppMenu";

const queryString = require('query-string');



const CustomisedComponentView = (props) => {
    const parsedQueryString = queryString.parse(props.location.search);
    const iframeLink = parsedQueryString.iframeLink.trim()
    return(
        <div>
            <AppMenu/>
        
        <div style={{width:"100%",height:"100vh", paddingTop:"60px"}}>
        <iframe
        src={iframeLink} 
        height="100% !important" 
        width="100%"
        />
        </div>
        </div>

    )
}
export default CustomisedComponentView 